.overall_info {
  background-color: white;
  display: grid;
  grid-template-columns: 39% 28% 33%;
  padding: 1.25rem 5%; //20px 5%;
  margin: 7.6rem 5% 0 5%;
  //margin: 123px 90px 0 24px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0 5%;
  }
  gap: 1.25rem; //20px;
  .livraison {
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 34px;
      color: #000;
      font-family: Titillium Web;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.2px;
    }
    padding-top: 1.25rem;
    p {
      font-size: 1.25rem;
      line-height: 1.0625rem; //17px;
      padding-top: 0.625rem; //10px;
      padding-bottom: 0.625rem; //10px;
      font-family: Titillium Web;
      text-align: left;
      a {
        text-decoration: none;
        position: relative;
        color: #0087c7;
        &::after {
          content: ' \f304';
          font-family: 'Font Awesome 6 Pro';
          position: absolute;
          //right: 0;
          //right: -100%;
          right: -30px;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.3s;
        }
      }
      span {
        font-size: 0.9375rem; //15px;
        font-weight: 400;
        line-height: 1.125rem; //18px;
      }
    }
    .no_padding_top {
      padding-top: 0;
    }
    .one_line {
      max-width: 500px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem; //10px;
      input[type='text'] {
        flex-grow: 1;
        padding: 0.15rem 1.25rem;
        border-radius: 0.3125rem;
        background: #ececec;
        color: #8b8888;
        font-family: 'Titillium Web';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem; /* 125% */
        letter-spacing: 0.0125rem;
        text-transform: lowercase;
        border: none;
      }

      input:focus {
        outline: 1px solid #0087c7;
        box-shadow: 0 0 0 1px #0087c7;
      }
    }
  }
  .paiement {
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem;
    h2 {
      margin-bottom: 60px;
      font-family: Titillium Web;
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.2px;
    }
    .services_paiement {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 18px;
      column-gap: 18px;
      img {
        //width: 4.375rem; //69.689px;
        //height: 4.375rem; //69.689px;
        width: 46px;
        height: 46px;
      }
    }
    .one_line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.625rem; //10px;
      img {
        width: 1rem; //16px;
        height: 1.3125rem; //21px;
      }
      p {
        font-size: 1.25rem;
        font-style: normal;
        color: #07a717;
      }
    }
  }
  .confirmation {
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem;

    h2 {
      margin-bottom: 29px;
      color: #000;
      font-family: Titillium Web;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.2px;
    }
    .one_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.625rem; //10px;
      width: 100%;
      p {
        font-size: 1.25rem; //20px;
        font-family: Titillium Web;
        color: #000;
        font-style: normal;
        font-weight: 400;
        font-style: normal;
        line-height: 25px;
        letter-spacing: 0.2px;
        span {
          font-weight: 600;
          span {
            font-size: 1.5625rem; //25px;
            font-weight: 700;
          }
        }
        strong {
          display: block;
          font-family: Oxanium;
          font-weight: 500;
        }
      }
      .orange {
        color: #e84610;
      }
    }
    .button_wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 1rem;
      &.with-cgu {
        flex-direction: column;
        .o_cgu {
          display: flex;
          align-items: center;
          gap: 0.625rem; //10px;
          font-size: 15px;
          font-weight: 600;

          input {
            width: 1rem;
            height: 1rem;
          }

          a {
            color: #0087c7;
            text-decoration: none;
          }
        }
      }
      button {
        margin: 0;
        background-color: #e84610;
        flex-grow: 1;
        padding: 1.1875rem 1.9375rem 1.1875rem 1.9375rem; //19px 31px 19px 31px;
        font-size: 1.5rem; //1.25rem; //20px;
        font-family: Titillium Web;
        line-height: 25px;
        letter-spacing: -0.15px;
        color: #fff;
        inline-size: auto;
        margin-top: 1.25rem; //20px;
        border-radius: 0.625rem; //10px;
        border: none;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .doted_border {
      border-bottom: 1px dotted #a8a8a7;
    }
    .doted_top_border {
      border-top: 1px dotted #a8a8a7;
    }
    .solid_border {
      border-bottom: 1px solid #a8a8a7;
    }
    .no_margin_top {
      margin-top: 0;
    }
    .no_margin_bottom {
      margin-bottom: 0;
    }
    .wrapped {
      border-radius: 5px;
      border: 1px solid #d4d4d4;
      background: #fff;
      padding: 0 1.25rem 1.25rem 1.25rem;
      margin-top: 1rem;
    }
  }
}

.user_info {
  padding: 5% 5% 30px 5%;
  display: flex;
  justify-content: space-between;
  .user_title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h2 {
      color: #000;
      font-family: Oxanium;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.18px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      color: #000;
      font-family: Titillium Web;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 60% */
      letter-spacing: 0.2px;
    }
  }
}
.orders_container {
  padding: 0 5% 3.125rem 5%; //20px 5%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem; //20px;
  background: #f7fafc;

  .order_content {
    display: grid;
    grid-template-columns: 19% 33% 14% 18% 16%; //242px 417px 175px 236px 201px;

    .title {
      display: flex;
      background: #ececec;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem; //20px;
      font-family: Oxanium;
      font-weight: 500;
      color: #000;
      padding: 0.875rem 0 0.625rem 0; //14px 0 10px 0;
    }
    .title_first {
      border-top-left-radius: 2rem; //32px;
    }
    .title_last {
      border-top-right-radius: 2rem; //32px;
    }
    .right_border {
      border-right: 1px solid #a8a8a7;
    }
    .bottom_border {
      border-bottom: 1px solid #a8a8a7;
    }
    .commandes {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding-top: 38px;
      padding-left: 6px;
      h4 {
        color: #000;
        font-family: Titillium Web;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      p {
        color: #5e5e5e;
        font-family: Titillium Web;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }

    .articles {
      display: flex;
      flex-direction: column;
      padding-top: 22px;
      padding-left: 51px;
      background-color: white;
      border-right: 0.0625rem solid #a8a8a7;

      .article {
        display: flex;
        align-items: flex-start;
        padding-bottom: 3.125rem; //50px;
        gap: 1.25rem; //20px;
        .vendor_details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .vendor {
            width: 5.6875rem; //91px;
            height: 1.3125rem; //21px;
          }
          img {
            width: 6.25rem; //100px;
            height: 5.75rem; //92px;
            &:hover {
              cursor: default;
            }
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-top: 1.8125rem; //29px;
          p {
            font-size: 14px;
            font-family: Titillium Web;
            margin-top: 0;
            margin-bottom: 0.3125rem; //5px; //0;
            span {
              font-weight: 600;
              font-size: 1.125rem; //18px;
            }
          }
          .orange_colored {
            font-weight: 600;
            color: #eb550e;
            margin-top: 0.1875rem; //3px;
            margin-bottom: 1rem; //16px;
          }
        }
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2.625rem; //42px;
      padding-bottom: 3.125rem; //50px;
      border-right: 1px solid #a8a8a7;
      background-color: white;
      p {
        font-size: 1.25rem; //20px;
        margin: 0;
        font-weight: 400;
        font-family: 'Titillium Web';
        line-height: 1.5625rem; //25px;
        text-transform: lowercase;
        color: #000;
      }
    }
    .quantity {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2.625rem; //42px;
      font-size: 1.5625rem; //25px;
      font-family: 'Titillium Web';
      background-color: white;
      border-right: 1px solid #a8a8a7;
      p {
        margin-bottom: 3.125rem; //50px;
        height: 8rem;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2.625rem; //42px;
      padding-bottom: 3.125rem; //50px;
      font-size: 1.5625rem; //25px;
      font-family: 'Titillium Web';
      background-color: white;
      p {
        margin: 0;
        font-family: 'Titillium Web';
        font-size: 1.25rem; //20px;
        font-weight: 400;
        line-height: 1.5625rem; //25px;
        letter-spacing: 0.2px;
        text-transform: lowercase;
      }
    }

    .status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      .one_line {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        button {
          width: 90px;
          height: 35px;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          font-family: Titillium Web;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.2px;
          text-transform: lowercase;
          &.green {
            background: #07a717;
          }
          &.yellow {
            background: #f7a400;
          }
          &.red {
            background: #eb0e0e;
          }
        }
        img {
          width: 16.479px;
          height: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .stripped {
    border: none;
    background: #f7fafc;
  }
}

.validation_container {
  padding: 0 5% 3.125rem 5%; //20px 5%;
  display: flex;
  flex-direction: column;
  background: #f7fafc;
  .mobile_articles {
    padding: 3.125rem 0 1rem 0;
    .mobile_articles_articles {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.25rem; //20px;
      margin-top: 1.25rem;
      .empty_card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 0;
        width: 100%;
        gap: 2rem;
        h2 {
          text-align: center;
          font-size: 32px;
        }
        button {
          margin: 0;
          padding: 0.8rem 2rem;
          height: auto;
          font-size: 18px;
          font-weight: bold;
          width: auto;
        }
      }
      .mobile_article {
        position: relative;
        width: 100%;
        padding: 1rem;
        background-color: #fff;
        .details {
          margin-top: 2rem;
          display: flex;
          gap: 1.8rem;
          .remove {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            cursor: pointer;
          }
          .article_img {
            width: 6.25rem;
            height: 5.75rem;
          }
          .marque_model {
            font-family: Oxanium;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.00525rem;
            text-transform: uppercase;
          }
          .title {
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.0125rem;
            text-transform: capitalize;
          }
          .category {
            font-family: 'Titillium Web';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: capitalize;
            margin-bottom: 0.3rem;
          }
          .caref {
            color: #eb550e;
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: uppercase;
            margin-bottom: 0.3rem;
          }
          .btn {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            span {
              display: inline-block;
              border-radius: 0.375rem;
              border: 0.5px solid #858181;
              background: #d4d4d4;
              padding: 0.5rem 0.6rem;
              color: #252525;
              font-family: Oxanium;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              letter-spacing: -0.0045rem;
              text-transform: uppercase;
            }
            img {
              width: 1.375rem;
              height: 1.3125rem;
              color: #484848;
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 1.25rem;
          .totalPrice {
            text-align: center;
            font-family: 'Titillium Web';
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5625rem; /* 125% */
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
          }
          .quantity {
            display: flex;
            align-items: center;
            > div {
              display: flex;
              span {
                font-family: 'Titillium Web';
                font-size: 1.5625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5625rem; /* 100% */
                letter-spacing: 0.0125rem;
                text-transform: lowercase;
                margin: 0 1.5rem;
              }
              button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.6875rem;
                height: 1.6875rem;
                border-radius: 0.3125rem;

                background-color: #002f5d;
                margin: 0;
                padding: 0;
                svg {
                  fill: #fff;
                }
                &:disabled {
                  background: #cfe2f4;

                  svg {
                    fill: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  p {
    font-size: 1.5625rem; //25px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 0.625rem; //10px;
    margin-top: calc(3rem - 1.25rem);
    img {
      width: 2.5rem; //40px;
      height: 2.5rem; //40px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  button {
    width: 14.75rem; //236px;
    height: 1.4615625rem; //23.385px;
    margin-left: 4rem;
    color: white;
    border-radius: 0.625rem; //10px;
    border: none;
    background-color: var(--main-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }

  .order_details {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .order_details_card {
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem;
      border-radius: 2rem;
      border: 1px solid #d4d4d4;
      background: #fff;
      .title {
        font-family: 'Titillium Web';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem; /* 125% */
        letter-spacing: 0.0125rem;
        padding-bottom: 0.8rem;
        border-bottom: 1px dashed #a8a8a7;
        margin-bottom: 0.8rem;
      }
      .prices {
        display: flex;
        font-family: 'Titillium Web';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem; /* 125% */
        letter-spacing: 0.0125rem;
        margin-bottom: 0.8rem;
        span {
          display: flex;
          flex: 1;
          flex-shrink: 0;
          width: 50%;
        }
        span:last-child {
          justify-content: flex-end;
        }
        @media screen and (max-width: 420px) {
        }
        &.hr {
          padding-bottom: 0.8rem;
          border-bottom: 2px solid #a8a8a7;
          margin-bottom: 0.8rem;
        }
        &.bold {
          font-family: 'Titillium Web';
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5625rem; /* 125% */
          letter-spacing: 0.0125rem;

          span:last-child {
            font-size: 1.5625rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5625rem; /* 100% */
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
            text-wrap: nowrap;
          }

          span,
          span:last-child {
            @media screen and (max-width: 620px) {
              font-size: 1.1rem;
            }
          }
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        a {
          padding: 1rem 2.5rem;
          border-radius: 0.625rem;
          background: #e84610;
          font-family: Titillium Web;
          font-size: 1.5625rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5625rem; /* 100% */
          letter-spacing: -0.00938rem;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .order_content_articles {
    background-color: #fff;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    display: flex;
    flex-direction: column;

    .empty_card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      width: 100%;
      gap: 2rem;

      h2 {
        text-align: center;
        font-size: 32px;
      }
      button {
        margin: 0;
        padding: 0.8rem 2rem;
        height: auto;
        font-size: 18px;
        font-weight: bold;
        width: auto;
      }
    }
    .article {
      display: grid;
      grid-template-columns: 40% 17% 16% 27%;
      > div {
        padding: 1.5rem;
      }
      > div:not(:last-child) {
        border-right: 1px solid #a8a8a7;
      }
      .details {
        display: flex;
        gap: 2rem;
        .remove {
          cursor: pointer;
        }
        .article_img {
          width: 6.25rem;
          height: 5.75rem;
        }
        .marque_model {
          font-family: Oxanium;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.00525rem;
          text-transform: capitalize;
        }
        .title {
          font-family: 'Titillium Web';
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.0125rem;
          text-transform: capitalize;
        }
        .category {
          font-family: 'Titillium Web';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.0125rem;
          text-transform: capitalize;
          margin-bottom: 0.3rem;
        }
        .caref {
          color: #eb550e;
          font-family: 'Titillium Web';
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.0125rem;
          text-transform: capitalize;
          margin-bottom: 0.3rem;
        }
        .btn {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.4rem;
            border-radius: 0.375rem;
            border: 0.5px solid #858181;
            background: #d4d4d4;
            padding: 0.5rem 0.6rem;
            color: #252525;
            font-family: Oxanium;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.0045rem;
            text-transform: uppercase;
          }
          img {
            width: 1.375rem;
            height: 1.3125rem;
            color: #484848;
          }
        }
      }
      .price,
      .totalPrice {
        text-align: center;
        font-family: 'Titillium Web';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem; /* 125% */
        letter-spacing: 0.0125rem;
        text-transform: lowercase;
      }
      .quantity {
        display: flex;
        justify-content: center;
        > div {
          display: flex;
          span {
            font-family: 'Titillium Web';
            font-size: 1.5625rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5625rem; /* 100% */
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
            margin: 0 1.5rem;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.6875rem;
            height: 1.6875rem;
            border-radius: 0.3125rem;

            background-color: #002f5d;
            margin: 0;
            padding: 0;
            svg {
              fill: #fff;
            }
            &:disabled {
              background: #cfe2f4;

              svg {
                fill: #000;
              }
            }
          }
        }
      }
    }
  }
  .order_content {
    // margin-top: calc(4.25rem - 1.25rem); //68px total
    margin-top: 20px;
    display: grid;
    grid-template-columns: 40% 17% 16% 27%; //510px 224px 197px 345px;

    .title {
      display: flex;
      background: #ececec;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem; //20px;
      font-family: Oxanium;
      font-weight: 500;
      color: #000;
      padding: 0.875rem 0 0.625rem 0; //14px 0 10px 0;
    }
    .title_first {
      border-top-left-radius: 2rem; //32px;
    }
    .title_last {
      border-top-right-radius: 2rem; //32px;
    }
    .right_border {
      border-right: 1px solid #a8a8a7;
    }
    .articles {
      display: flex;
      align-items: flex-start;
      padding-bottom: 3.125rem; //50px;
      border-right: 0.0625rem solid #a8a8a7;
      background-color: white;
      gap: 1.25rem; //20px;

      img {
        width: 0.9375rem; //15px;
        height: 1.0625rem; //17px;
        margin-left: 1.5rem; //24px;
        margin-top: 2.625rem; //42px;
        &:hover {
          cursor: pointer;
        }
      }
      .vendor_details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .vendor {
          width: 5.6875rem; //91px;
          height: 1.3125rem; //21px;
        }
        img {
          width: 6.25rem; //100px;
          height: 5.75rem; //92px;
          &:hover {
            cursor: default;
          }
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 1.8125rem; //29px;
        p {
          font-size: 14px;
          font-family: Titillium Web;
          margin-top: 0;
          margin-bottom: 0.3125rem; //5px; //0;
          span {
            font-weight: 600;
            font-size: 1.125rem; //18px;
          }
        }
        .orange_colored {
          font-weight: 600;
          color: #eb550e;
          margin-top: 0.1875rem; //3px;
          margin-bottom: 1rem; //16px;
        }
        .to_favoris {
          display: flex;
          align-items: center;
          button {
            width: 6.875rem; //110px;
            height: 1.75rem; //28px;
            border-radius: 0.4375rem; //7px;
            background: #d4d4d4;
            color: black;
            border: 0.5px solid #858181;
            margin-left: 0;
            font-size: 0.75rem; //12px;
          }
          img {
            margin-top: 0;
            min-width: 1.375rem; //22px;
            height: 1.3125rem; //21px;
            font-size: 1.5625rem; //25px;
          }
        }
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2.625rem; //42px;
      padding-bottom: 3.125rem; //50px;
      border-right: 1px solid #a8a8a7;
      background-color: white;
      p {
        font-size: 1.25rem; //20px;
        margin: 0;
        font-weight: 400;
        font-family: 'Titillium Web';
        line-height: 1.5625rem; //25px;
        text-transform: lowercase;
        color: #000;
      }
    }
    .quantity {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2.625rem; //42px;
      padding-bottom: 3.125rem; //50px;
      font-size: 1.5625rem; //25px;
      font-family: 'Titillium Web';
      background-color: white;
      border-right: 1px solid #a8a8a7;
      form {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 1.375rem; //22px;
        .quantity_input_container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.6875rem; //27px;
          input {
            width: 40%;
            height: 100%;
            border: none;
            @include toCenter();
            text-align: center;
            font-family: Titillium_Web;
            font-size: 1.125rem; //18px;
          }
          .quantity-minus {
            @include toCenter();
            background: #cfe2f4;
            border-radius: 5px;
            font-family: Titillium_Web;
            border: 0.0625rem solid #d9d9d9;
            user-select: none;
            width: 1.6875rem; //27px;
            height: 100%;

            font-size: 1.5625rem; //25px;
            border: 0.0625rem solid #d9d9d9;
            cursor: pointer;
          }
          .quantity-plus {
            @include toCenter();
            background: #002f5d;
            border-radius: 5px;
            font-family: Titillium_Web;
            border: 0.0625rem solid #d9d9d9;
            user-select: none;
            width: 1.6875rem; //27px;
            // height: 100%;
            height: 27px;
            font-size: 1.5625rem; //25px;
            color: white;
            border: 0.0625rem solid #d9d9d9;
            cursor: pointer;
          }
        }
        button {
          @include toCenter();
          background: #e84610;
          border: 0;
          color: white;
          width: 100%;
          height: 2.875rem; //46px;
          font-size: 1.0625rem; //17px;
          gap: 0.625rem; //10px;
          cursor: pointer;
          i {
            color: white;
            transition: 0.3s;
          }
          &:hover {
            i {
              padding-right: 0.625rem; //10px;
            }
          }
        }
        button.devis {
          background: var(--secondary-color);
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2.625rem; //42px;
      padding-bottom: 3.125rem; //50px;
      background-color: white;
      p {
        margin: 0;
        font-family: 'Titillium Web';
        font-size: 1.25rem; //20px;
        font-weight: 400;
        line-height: 1.5625rem; //25px;
        letter-spacing: 0.2px;
        text-transform: lowercase;
      }
    }
  }
  .summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 2rem; //32px;
    border: 1px solid #a8a8a7;
    margin-top: 0.75rem; //12px;
    padding: 1.25rem; //20px;
    background: #fff;
    grid-column: 3 / span 2;

    p {
      margin-top: 0;
      font-size: 1.25rem; //20px;
      font-weight: 400;
      font-style: normal;
      color: #000;
    }
    .one_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.625rem; //10px;
      width: 100%;
      p {
        margin: 0.4375rem 0; //7px 0;
        font-size: 1.25rem; //20px;
        font-weight: 400;
        font-style: normal;
        color: #000;
        span {
          font-weight: 600;
          span {
            font-size: 1.5625rem; //25px;
            font-weight: 700;
          }
        }
      }
      button {
        padding: 1.625rem 2.5rem 1.625rem 2.5rem; //26px 40px 26px 40px;
        color: #e84610;
        font-size: 1.5625rem; //25px;
        color: #fff;
        inline-size: auto;
        margin-top: 1.25rem; //20px;
      }
    }
    .doted_border {
      border-bottom: 1px dotted #a8a8a7;
    }
    .doted_top_border {
      border-top: 1px dotted #a8a8a7;
    }
    .solid_border {
      border-bottom: 2px solid #a8a8a7;
    }
  }
  .stripped {
    border: none;
    background: #f7fafc;
  }
}

.pieces_catalogue_page {
  padding: 3.125rem 5%; //50px 5%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  background-color: #f7fafc;
  gap: 1.375rem; //22px;
  .subtitles {
    display: flex;
    gap: 0.2875rem;
    align-items: center;
    img {
      align-self: start;
      width: 1.6875rem; //27px;
      aspect-ratio: 1/1;
    }
    p {
      span {
        font-weight: 700;
        color: #07a717;
        padding-left: 0.2rem;
      }
    }
  }
  .section_container {
    background: #f7fafc;
    display: grid;
    // grid-template-columns: 12.5625rem calc(100% - (12.5625rem + 0.625rem)); //201px 10px
    grid-template-columns: auto calc(100% - (16rem + 0.625rem));
    grid-gap: 0.625rem; //10px;
    padding-top: 4.375rem; //70px;
    .left_sidebar {
      width: 100%;
      height: 100%;
      max-height: 41.25rem; //660px;
      background: #eff2f4;
      position: relative;
      padding: 0.9375rem 1.25rem; //15px 20px;
      position: sticky;
      top: 0.625rem; //10px;
      & > i {
        height: 3.125rem; //50px;
        @include toCenter();
        cursor: pointer;
        &.prev_slide {
          border-bottom: 0.0625rem solid #a8a8a7;
        }
        &.next_slide {
          border-top: 0.0625rem solid #dedede;
        }
      }
      .swiper-container {
        height: calc(100% - (3.125rem * 2));
        overflow: hidden;
        .swiper-slide {
          border-bottom: 0.0625rem solid #a8a8a7;
          display: flex;
          align-items: center;
          gap: 3rem; // 10px
          font-size: 1.25rem; //20px;
          height: 106px;
          &.active {
            color: var(--main-color);
            img {
              filter: brightness(0) saturate(100%) invert(45%) sepia(39%)
                saturate(6767%) hue-rotate(2deg) brightness(99%) contrast(90%);
            }
          }
          img {
            //cursor: pointer;
            width: 4rem;
            height: 4rem;
            object-fit: cover;
          }
          & > * {
            pointer-events: none;
          }
        }
      }
    }
    .section_content {
      background-color: white;
      .breadcrumb {
        transform: translateY(-3.75rem);

        i {
          cursor: pointer;
        }

        @media screen and (max-width: 580px) {
          flex-direction: column;
          > div {
            width: 100%;
            align-items: center;
            span {
              display: inline-block;
              flex: auto;
              width: 100%;
              select {
                width: 100% !important;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 100%;
                background-position-y: 5px;
                background-size: 15%;
                border: 1px solid #dfdfdf;
                border-radius: 2px;
                margin-right: 2rem;
                padding: 1rem;
                padding-right: 2rem;
              }
            }
          }
          > div.sc {
            span {
              padding-left: 37px !important;
            }
          }
          i {
            display: none;
          }
        }
      }
      .catalogues {
        display: flex;
        flex-direction: column;
        gap: 3.125rem; //50px;
        .single_catalogue {
          display: grid;
          grid-template-columns: calc(20% - 1.25rem) calc(60% - 1.25rem) 20%;
          grid-gap: 1.25rem;
          border-bottom: 0.0625rem solid #8a8a8a;
          padding-bottom: 3.125rem; //50px;
          .left_side {
            display: flex;
            flex-direction: column;
            gap: 0.625rem; //10px;
            align-items: center;
            .img_maker {
              width: 50%;
              margin-bottom: 3.125rem; //50px;
            }
            .img_catalogue {
              width: 100%;
            }
          }
          .catalogue_details {
            display: flex;
            flex-direction: column;
            gap: 1.875rem; //30px;
            .top_details {
              display: flex;
              justify-content: space-between;
              p {
                display: flex;
                flex-direction: column;
                gap: 0.3125rem; //5px;
                font-size: 1.0625rem; //17px;
                span {
                  color: #5e5e5e;
                  font-weight: 600;
                  font-family: Titillium_Web;
                  &.orange {
                    color: #eb550e;
                  }
                }
              }
              .rating {
                display: flex;
                flex-direction: column;
                gap: 0.625rem; //10px;
                align-items: flex-end;
                .stars {
                  display: flex;
                  align-items: center;
                  gap: 0.625rem; //10px;
                  i {
                    font-size: 1.25rem; //20px;
                    color: #f7a034;
                    &.empty {
                      color: #dadada;
                    }
                  }
                }
                span {
                  color: #717171;
                }
              }
            }
            .bottom_details {
              display: flex;
              flex-direction: column;
              gap: 0.625rem; //10px;
              h3 {
                font-family: Titillium_Web;
              }
            }
          }
          .catalogue_right {
            width: 100%;
            .add_favorites {
              display: flex;
              align-items: center;
              gap: 0.625rem; //10px;
              font-weight: bold;
              i {
                font-size: 1.25rem; //20px;
                cursor: pointer;
                transition: 0.3s;
                &:hover {
                  color: #e84610;
                }
              }
            }
            .price_down {
              margin-top: 2.5rem; //40px;
              border-top: 0.0625rem solid #a1a1a1;
              width: 100%;
              padding-top: 0.6875rem; //11px;
              display: flex;
              flex-direction: column;
              gap: 1.0625rem; //17px;
              font-size: 1.125rem; //18px;
              h4 {
                font-size: 1.875rem; //30px;
                font-family: Titillium_Web_Bold;
              }
              p {
                display: flex;
                flex-direction: column;
                gap: 0.625rem; //10px;
                span {
                  display: flex;
                  align-items: center;
                  gap: 0.3125rem; //5px;
                  color: black;
                }
              }
              form {
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 1.375rem; //22px;
                .quantity_input_container {
                  display: flex;
                  align-items: center;
                  height: 2.1875rem; //35px;
                  input {
                    width: 40%;
                    height: 100%;
                    border: 0.0625rem solid #d9d9d9;
                    @include toCenter();
                    text-align: center;
                    font-family: Titillium_Web;
                    font-size: 1.125rem; //18px;
                  }
                  .quantity-minus,
                  .quantity-plus {
                    @include toCenter();
                    background: #e6e6e6;
                    font-family: Titillium_Web_Bold;
                    border: 0.0625rem solid #d9d9d9;
                    user-select: none;
                    width: 30%;
                    height: 100%;
                    font-size: 1.5625rem; //25px;
                    border: 0.0625rem solid #d9d9d9;
                    cursor: pointer;
                  }
                }
                button {
                  @include toCenter();
                  background: #e84610;
                  border: 0;
                  color: white;
                  width: 100%;
                  height: 2.875rem; //46px;
                  font-size: 1.0625rem; //17px;
                  gap: 0.625rem; //10px;
                  cursor: pointer;
                  i {
                    color: white;
                    transition: 0.3s;
                  }
                  &:hover {
                    i {
                      padding-right: 0.625rem; //10px;
                    }
                  }
                }
                button.devis {
                  background: var(--secondary-color);
                }
              }
            }
          }
          .table {
            width: 100%;
            grid-column: 1 / 3;
            .row {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.1875rem 0.625rem; //3px 10px;
              &.gray_row {
                background: #efefef;
                border: 0.0625rem solid #d5d5d5;
              }
              span {
                font-family: Titillium_Web;
                b {
                  font-weight: bold;
                }
                &:nth-child(2) {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
  .pages_nav {
    margin-left: auto;
    display: flex;
    align-items: center;
    .square {
      @include toCenter();
      cursor: pointer;
      height: 2.5rem; //40px;
      width: 2.75rem; //44px;
      transition: 0.3s;
      border: 0.0625rem solid #dee2e7;
      &:hover {
        padding-right: 0.3125rem; //5px;
      }
      &:first-child {
        border-top-left-radius: 0.3125rem; //5px;
        border-bottom-left-radius: 0.3125rem; //5px;
      }
      &:last-child {
        border-top-right-radius: 0.3125rem; //5px;
        border-bottom-right-radius: 0.3125rem; //5px;
      }
      &.disabled {
        cursor: not-allowed;
        color: #8b96a5;
      }
      &.active {
        background-color: var(--main-color);
        color: white;
      }
    }
  }
}

@media (max-width: 1024px) {
  .pieces_catalogue_page {
    padding-right: 0.625rem; //10px;
    padding-left: 0.625rem; //10px;
    .section_container {
      grid-template-columns: 100%;
      padding-top: 4.25rem; //20px;
      .section_content {
        .breadcrumb {
          transform: translateY(-4.8rem);
        }
        .catalogues {
          .single_catalogue {
            display: grid;
            grid-template-columns: calc(50% - 15px) 50%;
            grid-gap: 1rem; //15px;
            position: relative;
            padding-top: 1.875rem; //30px;
            .left_side {
              .pieces_images {
                width: 7.3125rem;
                .img_catalogue {
                  border-radius: 0.3125rem; //5px;
                  padding: 0.3125rem 0.625rem; //5px 10px;
                }
              }

              .mobile_rating {
                display: flex;
                align-items: center;
                gap: 0.625rem; //10px;
                margin-top: 1.5rem; //24px;
                .stars {
                  display: flex;
                  align-items: center;
                  gap: 0.3125rem; //5px;
                  i {
                    font-size: 1.0625rem; //17px;
                    color: #f7a034;
                    &.empty {
                      color: #858080;
                    }
                  }
                }
                span {
                  font-size: 0.9375rem; //15px;
                }
              }
            }
            .catalogue_details {
              .top_details {
                flex-direction: column;
                gap: 0.9375rem; //15px;
                p {
                  gap: 0;
                  font-size: 1.25rem; //20px;
                  span:nth-child(3) {
                    color: var(--main-color);
                  }
                }
                .description {
                  display: flex;
                  flex-direction: column;
                  gap: 0.625rem; //10px;
                  margin-top: auto;
                  .availability {
                    display: flex;
                    align-items: center;
                    gap: 0.625rem; //10px;
                    font-size: 1.25rem; //20px;
                    font-weight: 600;
                    img {
                      width: 1.5625rem; //25px;
                    }
                  }
                  strong {
                    font-weight: 700;
                    font-size: 1.875rem; //30px;
                    @media screen and (max-width: 350px){
                      font-size: 1.5rem; //24px;
                    }
                    font-family: Titillium_Web_Bold;
                  }
                  p {
                    font-size: 1.125rem; //18px;
                  }
                }
              }
            }
            .catalogue_right {
              width: 100%;
              grid-column: 1 / 3;
              .add_favorites {
                position: absolute;
                right: 0;
                top: 0;
                i {
                  font-size: 1.625rem; //26px;
                  color: #adadad;
                }
              }
              .price_down {
                margin-top: 0;
                border-top: 0;
                flex-direction: row;
                gap: 1.25rem; //20px;
                .details_button {
                  @include toCenter();
                  gap: 0.625rem; //10px;
                  width: calc(40% - 1.25rem);
                  background: #858080;
                  color: white;
                  height: 3.75rem; //60px;
                  font-size: 1.25rem; //20px;
                  border-radius: 0.375rem; //6px;
                  i {
                    transition: 0.2s;
                  }
                  &.opened {
                    i {
                      transform: rotate(180deg);
                    }
                  }
                }
                form {
                  width: 60%;
                  display: flex;
                  flex-direction: row;
                  gap: 22px;
                  .quantity_input_container {
                    justify-content: flex-start;
                    height: 100%;
                    position: relative;
                    border: 0.0625rem solid #c1c1c1;
                    border-radius: 0.3125rem; //5px;
                    input {
                      width: 70%;
                      border: 0;
                    }
                    .quantity-minus,
                    .quantity-plus {
                      position: absolute;
                      padding-right: 6%;
                      right: 0;
                      top: 50%;
                      height: 30%;
                      width: 30%;
                      bottom: 0;
                      background: none;
                      border: 0;
                      &::after {
                        @include toCenter();
                        content: "\f106";
                        font-family: "Font Awesome 6 Pro";
                        font-size: 1rem; //16px;
                        font-weight: 900;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: white;
                      }
                    }
                    .quantity-plus {
                      top: 20%;
                    }
                    .quantity-minus {
                      bottom: 20%;
                      &::after {
                        content: "\f078";
                      }
                    }
                  }
                  button {
                    border-radius: 0.3125rem; //5px;
                    height: 100%;
                    i {
                      font-size: 1.5625rem; //25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .pages_nav {
      margin: 0 auto;
    }
  }
}

/* Your custom CSS styles */
.hero_slider {
  width: 100%;
  height: 37.5rem; //600px;
  overflow: hidden;
  position: relative;
  .swiper-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 0 10%;
    gap: 5rem; //80px;
    img.background_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -2;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(96.63deg, rgba(0, 0, 0, 0.8) 35.83%, rgba(0, 0, 0, 0) 97.06%);
    }
    h2 {
      position: relative;
      color: white;
      font-size: 2.5rem; //40px;
      max-width: 33.75rem; //540px;
      line-height: 3.125rem; //50px;
      font-weight: 400;
    }
    a {
      background: var(--main-color);
      color: white;
      position: relative;
      width: 14.75rem; //236px;
      height: 2.9375rem; //47px;
      display: flex;
      align-items: center;
      border-radius: 0.625rem; //10px;
      padding: 0 1.25rem; //20px;
      font-size: 1.375rem; //22px;
      &::after {
        content: '\f061';
        font-family: 'Font Awesome 6 Pro';
        position: absolute;
        right: 0.9375rem; //15px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
      }
      &:hover::after {
        right: 1.25rem; //20px;
      }
    }
  }
  .next_slide,
  .prev_slide {
    color: #fff;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 1.5625rem; //25px;
  }
  .next_slide {
    right: 5%;
    cursor: pointer;
  }
  .prev_slide {
    left: 5%;
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .hero_slider {
    height: 25.6875rem; //411px;
    .swiper-slide {
      padding: 0 2.625rem; //42px;
      gap: 2.5rem; //40px;
      justify-content: center;
      h2 {
        font-size: 1.875rem; //30px;
        line-height: 2.34375rem; //37.5px;
        text-align: center;
      }
      a {
        width: 11.5rem; //184px;
      }
    }
    .next_slide {
      right: 0.625rem; //10px;
    }
    .prev_slide {
      left: 0.625rem; //10px;
    }
  }
}

.pieces_catalogue {
  padding: 3.125rem 5%; //50px 5%
  display: flex;
  flex-direction: column;
  //gap: 1.375rem; //22px;
  h2 {
    display: flex;
    align-items: center;
    gap: 0.925rem; //10px;
    flex-wrap: wrap;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-family: Oxanium;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
    span {
      display: flex;
      align-items: center;
      gap: 0.225rem;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      color: var(--main-color);
      b {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .section_container {
    display: grid;
    //grid-template-columns: 12.5625rem calc(100% - (12.5625rem + 0.625rem));
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.825rem 0;
    background-color: #fff;
    @media screen and (max-width: 1024px) {
      background-color: transparent;
    }
    .left_sidebar {
      width: 100%;
      height: 100%;
      max-height: 46.25rem; //740px;
      background: #eff2f4;
      position: relative;
      padding: 0.9375rem 1.25rem; //15px 20px;
      & > i {
        height: 3.125rem; //50px;
        @include toCenter();
        cursor: pointer;
        &.prev_slide {
          border-bottom: 0.0625rem solid #a8a8a7; //1px
        }
        &.next_slide {
          border-top: 0.0625rem solid #dedede;
        }
      }
      .swiper-container {
        height: calc(100% - (3.125rem * 2));
        overflow: hidden;
        text-align: center;
        .swiper-slide {
          border-bottom: 0.0625rem solid #a8a8a7;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 106px;
          //gap: 1.3125rem;
          gap: 3rem; // 10px
          //font-size: 1.25rem;
          color: #3a3a3a;
          font-family: Inter;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.2px;
          &:hover {
            cursor: pointer;
          }

          &.active {
            color: var(--main-color);
            img {
              filter: brightness(0) saturate(100%) invert(45%) sepia(39%) saturate(6767%)
                hue-rotate(2deg) brightness(99%) contrast(90%);
            }
          }
          img {
            //cursor: pointer;
            width: 4rem;
            height: 4rem;
            object-fit: cover;
          }
          & > * {
            pointer-events: none;
          }
        }
      }
    }
    .section_content {
      width: 100%;
      @media screen and (min-width: 1024px) {
        max-height: 46.25rem;
      }
      overflow-y: auto;
      padding: 0 0 2rem 0; //0 20px;
      .piece_category {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .category_name {
          margin-top: 1.9375rem; //31px;
          padding-left: 3rem; //80px;
          margin-bottom: 1.8125rem; // 29px;
          h2 {
            color: #000;
            font-family: Oxanium;
            font-size: 25px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.15px;
          }
        }
        .spread {
          margin-top: 5.875rem; //9rem;
        }
        .category_content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 3rem; //144px;
          grid-row-gap: 2.125rem; //34px;
          padding: 0 1rem;
          width: 100%;

          @media screen and (max-width: 1230px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 3rem; //144px;
          }
          .single_category {
            display: flex;
            flex-direction: column;
            gap: 0.9375rem; //15px;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 6.25rem; //100px;
              object-fit: contain;
            }
            span {
              color: #3a3a3a;
              font-family: Oxanium;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.2px;
            }
          }
          .category_title {
            grid-column: 1 / span 5;
          }
        }
        button {
          margin: {
            left: auto;
            right: auto;
            //top: 9rem; //144px;
            top: 6rem; //96.5px
          }
          @include toCenter();
          background: #f4f3f3;
          border: 0.0625rem solid #dde2e4;
          border-radius: 0.3125rem; //5px;
          height: 3.1875rem; //51px;
          width: 10.4375rem; //167px;
          font-weight: 700;
          font-size: 1.25rem; //20px;
          color: #252c32;
          text-align: center;
          font-family: Oxanium;
          font-style: normal;
          line-height: 24px; /* 120% */
          letter-spacing: -0.12px;
          cursor: pointer;
        }
      }
    }
  }
  .view_more {
    margin: {
      left: auto;
      right: auto;
      top: 0px;
    }
    @include toCenter();
    background: #f4f3f3;
    border: 0.0625rem solid #dde2e4;
    border-radius: 0.3125rem; //5px;
    height: 2.8125rem; //45px;
    width: 9.375rem; //150px;
    font-weight: 700;
    font-size: 1rem; //16px;
    cursor: pointer;
  }
}
@media (max-width: 1024px) {
  .pieces_catalogue {
    padding: 0;
    h2 {
      padding-left: 5%;
    }
    .section_container {
      column-gap: 1rem;
      padding: 1rem 5%;
      &.bottom {
        display: flex;
        flex-direction: column;
        padding: 1rem 5%;
        background-color: #fff;
        .section_content {
          .piece_category {
            padding: 1rem 0rem;
            .category_content {
              padding: 1rem 0;
              .single_category {
                img {
                  width: 4.8rem;
                }
                span {
                  color: #3a3a3a;
                  font-family: Oxanium;
                  font-size: 1.25rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 0.0125rem;
                }
              }
            }
          }
        }
      }
      grid-template-columns: 1fr 1fr;
      .section_content {
        width: 100%;
        .piece_category {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          .category_name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 0.5rem;
            background: #fff;
            width: 100%;
            height: 100%;
            border-radius: 0.3125rem; //5px;
            border: 1.5px solid #d0d0d0;
            background: #fff;
            font-family: Inter;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.0125rem;
            padding: 0.8rem 1.6rem;
            margin-top: 0;
            margin-bottom: 0;

            @media screen and (max-width: 392px) {
              font-size: 3.5vw;
            }

            img {
              width: 3rem;
              height: 3rem;
              object-fit: contain;
            }

            &.active {
              background-color: var(--main-color);
              color: #fff;

              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                  hue-rotate(334deg) brightness(107%) contrast(101%);
              }
            }
          }
          .piece_category_mobile_title {
            display: flex;
            justify-content: space-between;
            color: #3a3a3a;
            text-align: left;
            font-family: Oxanium;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.0125rem;
          }
          .category_content {
            display: none;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 2.125rem; //34px;

            .single_category {
              display: flex;
              flex-direction: row;
              gap: 1.875rem; //30px;
            }
          }
          button {
            margin: 0 auto;
            margin-top: 1.875rem; //30px;
          }
        }
      }
    }
  }
}

.form_section {
  padding: 6.25rem 5%;
  padding-top: 3.125rem; //50px;
  padding-bottom: 1.8125rem; //29px;
  display: flex;
  flex-direction: column;
  gap: 2rem; //32px;
  h2 {
    font-size: 1.875rem; //30px;
    font-family: Oxanium;
    color: #000;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
  }
  .section_content {
    display: flex;
    justify-content: space-between;
    padding: 1.875rem; //30px;
    position: relative;
    height: auto;
    background: linear-gradient(97.47deg, rgba(1, 37, 71, 0.9) 28.76%, rgba(0, 47, 93, 0) 153.5%),
      url(/assets/images/form_bg.png);
    .text_side {
      margin-top: 15%;
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 6.25rem; //100px;
      h3 {
        font-size: 1.875rem; //30px;
        line-height: 2.34375rem; //37.5px;
        color: white;
      }
      p {
        font-size: 1.25rem; //20px;
        line-height: 1.5rem; //24px;
        color: white;
      }
    }
    .form_side {
      background: white;
      border-radius: 0.375rem; //6px;
      padding: 1.3125rem 2.1875rem; //21px 35px;
      width: 68%;
      h3 {
        font-size: 1.8125rem; //29px;
      }
      form {
        margin-top: 3.75rem; //60px;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          justify-content: space-between;
        }
        .inputs_container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 1.25rem; //20px;
          grid-column-gap: 2.5rem; //40px;

          input {
            color: #8b96a5;
          }

          input,
          select,
          textarea {
            width: 100%;
            background: #ffffff;
            border: 0.0625rem solid #dee2e7;
            border-radius: 0.375rem; //6px;
            height: 2.625rem; //42px;
            padding: 0 0.625rem; //0 10px;
            font-size: 1.0625rem; //17px;
            &::placeholder {
              color: #8b96a5;
            }
          }
          select {
            color: #8b96a5;
            option {
              color: black;
            }
          }
          textarea {
            grid-column: 1 / 3;
            height: 8.0625rem; //129px;
            resize: none;
            padding: 0.9375 0.625rem; //15px 10px;
          }
          .overlay_container {
            position: relative;
            grid-column: 1 / 3;
            height: 8.0625rem; //129px;
            resize: none;
            padding: 0.9375 0.625rem; //15px 10px;
            cursor: pointer;
            .files_overview {
              display: flex;
              position: absolute;
              background-color: white;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              .file_overview {
                flex: 1;
                img {
                  flex: 1;
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                p {
                  text-align: center;
                }
              }
            }
            .picture_hide {
              opacity: 0;
              position: absolute;
              z-index: 10;
              top: 0;
              width: 100%;
              height: 100%;
              visibility: hidden;
            }

            .picture_overlay {
              top: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              border: 2px dashed #e3e3e3;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 2.5rem; //40px;
              padding-right: 2.5rem; //40px;
              gap: 2rem;
              img {
                width: 1.625rem; //26px;
                height: 1.625rem; //26px;
              }
              .right_image {
                width: 2.3125rem; //37px;
                height: 2.3125rem; //37px;
                margin-left: auto;
              }
              p {
                font-size: 1rem; //16px;
                color: #9c9c9c;
                font-weight: 300;
                font-style: italic;
                font-family: Poppins;
              }
            }
          }

          .quantity_input_container {
            background: #ffffff;
            border: 0.0625rem solid #dee2e7;
            border-radius: 0.375rem; //6px;
            height: 2.625rem; //42px;
            padding: 0 0.625rem; //0 10px;
            font-size: 1.0625rem; //17px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              color: #8b96a5;
            }
            .quantity {
              display: flex;
              align-items: center;
              div {
                cursor: pointer;
                user-select: none;
                font-size: 1.125rem; //18px;
                font-weight: bold;
              }
              input {
                all: revert;
                text-align: center;
                padding: 0 !important;
                border: 0;
                width: 2.5rem; //40px;
                font-size: 1.25rem; //20px;
                font-family: Oxanium_Regular;
                pointer-events: none;
              }
            }
          }
          .img_title {
            display: flex;
            padding-right: 5px;
            color: #858080;
            border: 0.0625rem solid #dee2e7;
            border-radius: 0.675rem; //6px;
            font-size: 15px;
            input {
              border: none;
              outline: none;
              flex-grow: 1;
            }
            img {
              width: 20px;
              &:hover {
                cursor: pointer;
              }

              vertical-align: middle;
            }
          }
        }
        .radio_options {
          display: flex;
          flex-direction: column;
          gap: 1.25rem; //20px;
          padding: 1.75rem 0; //28px 0;
          width: 100%;
          .options_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .single_option {
              display: flex;
              align-items: center;
              gap: 1.25rem; //20px;
              cursor: pointer;
              label {
                font-size: 1.25rem; //20px;
                cursor: pointer;
              }
              input {
                height: 1.875rem; //30px;
                width: 1.875rem; //30px;
                aspect-ratio: 1 / 1;
                background: #d9d9d9;
                border-radius: 50%;
                appearance: none;
                border: 0.3125rem solid #d9d9d9;
                cursor: pointer;
                transition: 0.3s;
                &:checked {
                  background: #002f5d;
                }
              }
            }
          }
        }

        button {
          border-radius: 0.375rem; //6px;
          padding: 0px 1rem 0px 1rem;
          height: 3.4375rem; //55px;
          width: 14.0625rem; //225px;
          background: linear-gradient(180deg, #127fff 0%, #0067ff 100%);
          color: white;
          border: 0;
          font-size: 1.25rem; //20px;
          margin: 0 auto;
          margin-top: 2.5rem; //40px;
          cursor: pointer;
        }

        .btn-submit {
          display: flex;
          align-items: center;
          justify-content: right;
          gap: 1.5rem;

          button.open_sent_pi.back {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            background: none;
            background-color: #8b96a5;
          }
        }

        .nextStep {
          display: flex;
          justify-content: flex-end;
          margin-top: 1.4rem; //40px;
          button.nextStepBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            background: none;
            font-weight: bold;
            margin: 0;
            padding: 0.5rem 2rem;
            height: auto;
            width: auto;
            background-color: var(--main-color);
            margin-top: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .form_section {
    padding: 6.25rem 0; //100px 0;
    .img_title {
      padding-top: 0 !important;
      border-radius: 0.6rem !important;
    }
    .title {
      color: var(--base-color-dark, #1c1c1c);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Oxanium;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem; /* 140% */
      letter-spacing: -0.0125rem;
    }
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0.2rem;
        height: 100px;
        opacity: 0;
        &-active {
          padding-top: 2.5rem;
          height: auto;
          opacity: 1;
        }
      }

      .swiper-pagination {
        top: 0 !important;
        height: 20px;
        .swiper-pagination-bullet {
          width: 1.875rem; //30px;
          height: 0.3125rem; //5px;
          border-radius: 0.625rem; //10px;
          background-color: #cccccc;
          opacity: 1;
          transition: opacity 0.2s ease-out;
          cursor: pointer;
          transition: 0.3s;
          &.swiper-pagination-bullet-active {
            width: 3.875rem; //62px;
            background-color: var(--main-color);
          }
        }
      }
    }
    h2 {
      width: 100%;
      padding-left: 5%;
    }
    .section_content {
      flex-direction: column;
      padding: 1.25rem 0.625rem; //20px 10px;
      gap: 1.875rem; //30px;
      .text_side {
        margin-top: unset;
        width: 95%;
        margin: 0 auto;
        gap: 0.9375rem; //15px;
      }
      .form_side {
        padding: 3.1875rem 1.25rem; //51px 20px;
        width: 100%;
        h3 {
          font-size: 1.5625rem; //25px;
        }
        form {
          margin-top: 1.25rem; //20px;
          display: flex;
          flex-direction: column;
          .inputs_container {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            input {
              width: 100%;
            }
            textarea {
              grid-column: 1;
            }
          }
          .radio_options {
            .options_container {
              flex-direction: column;
              align-items: unset;
              justify-content: flex-start;
              gap: 1.25rem; //20px;
            }
          }
        }
      }
    }
  }
}

.logos_section_container {
  width: 100%;
  padding-top: 69px;
  padding-bottom: 120px;
  background-color: #fff;
  &.flip {
    background-color: #f7fafc;
  }
  .logos_section {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 6.25rem; //100px;
    padding-bottom: 2.5rem; //40px;
    overflow: hidden;
    h2 {
      font-size: 1.875rem; //30px;
      margin-bottom: 3.125rem; //50px;
      text-align: center;
      color: #000;
      font-family: Oxanium;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      text-transform: uppercase;
    }
    .logos_slider {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 1.875rem; //0 30px;
      .swiper-wrapper {
        display: flex;
        align-items: center;
        overflow: visible;
        .swiper-slide {
          max-width: 100% !important;
          height: 5.015rem; //80.24px;
          object-fit: contain;
        }
      }
      .next_slide,
      .prev_slide {
        color: black;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) !important;
        z-index: 1;
        font-size: 1.5625rem; //25px;
        cursor: pointer;
      }
      .next_slide {
        right: 0;
      }
      .prev_slide {
        left: 0;
      }
      /* Pagination bullets */
      .swiper-pagination {
        top: calc(100% + 1.25rem) !important;
        .swiper-pagination-bullet {
          width: 1.875rem; //30px;
          height: 0.3125rem; //5px;
          border-radius: 0.625rem; //10px;
          background-color: #cccccc;
          opacity: 1;
          transition: opacity 0.2s ease-out;
          cursor: pointer;
          transition: 0.3s;
          &.swiper-pagination-bullet-active {
            width: 3.875rem; //62px;
            background-color: var(--main-color);
          }
        }
      }
      .swiper-wrapper {
        &.dot {
          .swiper-pagination {
            .swiper-pagination-bullet {
              width: 0.3125rem;
              height: 0.3125rem;
              border-radius: 50%;
              background-color: #cccccc;
              opacity: 1;
              transition: opacity 0.2s ease-out;
              cursor: pointer;
              transition: 0.3s;
              &.swiper-pagination-bullet-active {
                width: 0.575rem;
                border-radius: 0.3125rem;
                background-color: var(--main-color);
              }
            }
          }
        }
      }
    }
  }
}

.products_section {
  background-color: #f7fafc;
  padding: 6.25rem 5%; //100px 5%;
  @media screen and (max-width: 764px) {
    padding: 6.25rem 1rem; //100px 0;
  }
  h2 {
    font-size: 1.875rem; //30px;
    color: #000;
    font-family: Oxanium;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }
  .products_slider {
    position: relative;
    margin-top: 3.75rem; //60px;
    padding: 0 1.875rem; //30px;
    .swiper-wrapper {
      display: flex;
      overflow-y: visible;
      padding: 2rem 0;
      .swiper-pagination {
        display: none;
        @media screen and (max-width: 1024px) {
          display: block;
          .swiper-pagination-bullet {
            width: 1.875rem; //30px;
            height: 0.3125rem; //5px;
            border-radius: 0.625rem; //10px;
            background-color: #cccccc;
            opacity: 1;
            transition: opacity 0.2s ease-out;
            cursor: pointer;
            transition: 0.3s;
            &.swiper-pagination-bullet-active {
              width: 3.875rem; //62px;
              background-color: var(--main-color);
            }
          }
        }
      }

      &.dot {
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 0.3125rem;
            height: 0.3125rem;
            border-radius: 50%;
            background-color: #cccccc;
            opacity: 1;
            transition: opacity 0.2s ease-out;
            cursor: pointer;
            transition: 0.3s;
            &.swiper-pagination-bullet-active {
              width: 0.575rem;
              border-radius: 0.3125rem;
              background-color: var(--main-color);
            }
          }
        }
      }

      .single_product {
        position: relative;
        background: white;
        padding: 2.1875rem 1.25rem; //35px 20px;
        width: 100%;
        max-width: 19.625rem;
        height: 460px;
        @include toCenter();
        justify-content: flex-end;
        min-height: 27.5rem !important; //440px !important;
        flex-direction: column;
        gap: 1.75rem; //28px;
        flex-shrink: 0;
        &.favorite {
          .heart {
            position: absolute;
            right: 1.25rem; //20px;
            top: 1.25rem; //20px;
            width: 1.25rem; //20px;
            height: 1.25rem; //20px;
            font-size: 1.25rem; //20px;
            cursor: pointers;
          }
        }
        .heart {
          position: absolute;
          right: 1.25rem; //20px;
          top: 1.25rem; //20px;
          width: 1.25rem; //20px;
          height: 1.25rem; //20px;
          cursor: pointer;
          color: #002f5d;
        }
        img {
          width: 6.25rem; //100px;
          height: 6.25rem; //100px;
        }
        h3 {
          font-size: 1.25rem; //20px;
          font-weight: 400;
          text-align: center;
        }
        p {
          text-align: center;
          font-weight: 400;
          line-height: 1.625rem; //26px;
        }
        strong {
          font-size: 1.5625rem; //25px;
        }
        button.btn {
          margin-top: auto;
          width: 80%;
          background: var(--main-color);
          border-radius: 0.375rem; //6px;
          height: 2.5rem; //40px;
          border: 0;
          cursor: pointer;
          transition: 0.3s;
          flex-shrink: 0;
          &:hover {
            padding-left: 0.625rem; //10px;
          }
          i {
            color: white;
            font-size: 1rem; //16px;
          }
        }

        button.quantity {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          &:hover {
            background-color: transparent;
            padding-left: 0;
          }
        }
      }
    }
    .next_slide,
    .prev_slide {
      color: black;
      position: absolute;
      top: 50%;
      z-index: 1;
      font-size: 1.5625rem; //25px;
      cursor: pointer;
    }
    .next_slide {
      right: 0;
    }
    .prev_slide {
      left: 0;
    }
  }
}
@media (max-width: 1024px) {
  .products_section {
    .products_slider {
      .next_slide,
      .prev_slide {
        display: none !important;
      }
    }
  }
}

.about_section {
  display: flex;
  flex-direction: column;
  gap: 1.875rem; //30px;
  padding: 1.875rem 5%; //30px 5%;
  background: #fff;
  h2 {
    font-size: 1.875rem; //30px;
  }
  .section_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p {
      width: 70%; //47%;
      margin-bottom: 3.3125rem; //53px;
      font-size: 1.875rem; //30px;
      //line-height: 2.34375rem; //37.5px;
      line-height: normal;
      font-style: normal;
      font-family: Oxanium;
      color: #3a3a3a;
      text-align: center;
      font-weight: 500;
    }
    a {
      width: 70%; //50%;
      img {
        width: 100%;
        margin-bottom: 3.875rem; //62px;
      }
    }
  }
}

.methodology_section {
  display: flex;
  flex-direction: column;
  gap: 1.875rem; //30px;
  padding: 1.875rem 5%; //30px 5%;
  background: #f7fafc;
  h1 {
    font-size: 1.8rem;
    text-align: left;
  }
  h2 {
    font-size: 1.875rem; //30px;
  }
  .title_1 {
    color: #000;
    font-size: 1.875rem; //30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
    font-family: Oxanium;
  }
  .title_2 {
    color: #000;
    font-size: 1.5625rem; //25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
    font-family: Oxanium;
  }
  .section_title {
    font-size: 2.5rem; //40px;
    font-family: Oxanium;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.92px;
  }
  p {
    //      width: 47%;
    font-size: 21.33px;
    line-height: 1.875rem; //30px;
    color: #7c7c7c;
    font-family: Oxanium;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4.125rem; //66px;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .about_section {
    .section_content {
      flex-direction: column;
      gap: 3.125rem; //50px;
      a,
      p {
        width: 100%;
      }
      p {
        font-size: 1.5625rem; //25px;
        line-height: 1.953125rem; //31.25px;
      }
    }
  }
}

.cgu {
  padding: 2rem 0;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  li {
    list-style: '-  ' !important;
  }
}

.diagnostic_about {
  width: 90%;
  margin: 0 auto;
  margin-top: 8.125rem; //130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.75rem; //60px;
  img {
    width: 30.75rem; //492px;
    max-width: 30%;
  }
  .right_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.9375rem; //15px;
    padding-right: 0;
    h2 {
      font-size: 2.5rem; //40px;
    }
    .section_title {
      font-family: Oxanium;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.92px;
    }
    p {
      width: 100%;
      font-family: Oxanium;
      font-style: normal;
      font-weight: 400;
      font-size: 1.333125rem; //21.33px;
      line-height: 28px;
      letter-spacing: 0.023em;
      color: #7c7c7c;
      margin: 0 0;
      padding: 0 0;
    }
  }
}

.text_out_section {
  display: flex;
  flex-direction: column;
  background: #f7fafc;
  p {
    width: 90%;
    margin: 0 auto;
    margin-top: 4.4375rem; //71px;
    font-family: IBM_Plex_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 1.333125rem; //21.33px;
    line-height: 28px;
    letter-spacing: 0.023em;
    color: #7c7c7c;
  }
  .diagnostic_about {
    margin-top: 3.4375rem;
  }
}
.white_bg {
  margin-top: 4.1875rem; //67px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #fff;
  .diagnostic_about {
    margin-top: 8.125rem - 4.1875rem;
  }
}
@media (max-width: 1024px) {
  .diagnostic_about {
    width: 90%;
    flex-direction: column;
    img {
      max-width: 100%;
    }
    .right_text {
      width: 100%;
    }
  }
}
.rendez_vous {
  margin: 0 auto;
  width: 90%;
  margin-top: 6.25rem; //100px;
  display: flex;
  flex-direction: column;
  gap: 1.875rem; //30px;
  background: #f7fafc;
  padding-bottom: 2rem;
  h2 {
    font-size: 2.5rem; //1.875rem; //30px;
  }
  p {
    margin-top: 0;
    padding-top: 0;
    font-weight: 600;
    font-size: 1.5625rem; //25px;
    line-height: 1.9375rem; //31px;
    display: flex;
    align-items: center;
    letter-spacing: 0.023em;
    color: #333333;
    &.dist {
      margin-bottom: 80px;
    }
    // max-width: 54.375rem; //870px;
  }
  .form_wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;
    .left_pane_info {
      width: 42%;
      display: flex;
      flex-direction: column;
      p {
        font-size: 1.25rem; //20px;
        display: block;
        text-align: left;
        b {
          display: inline-flex;
          color: #003969;
          font-family: Oxanium;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.02875rem;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .line_of_info {
        width: 100%;
        margin-top: 2rem;
        gap: 1rem;
        display: flex;
        img {
          width: 1.5rem; //24px
        }
        p {
          font-size: 1rem; //16px;
        }
      }
    }
    form {
      padding-top: 3rem; //40px;
      width: 58%;
      background: #fff;

      .square_radio_options {
        display: flex;
        flex-direction: column;
        gap: 1.25rem; //20px;
        padding: 1.75rem 0; //28px 0;
        width: calc(100% - 3.75rem * 2);
        margin-bottom: 2rem;
        margin-left: 3.75rem; //60px
        margin-right: 3.75rem; //60px
        .options_container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 0.55rem;
          width: 100%;
          &.reduce {
            padding-left: 40px;
            padding-right: 60px;
          }
          .single_option {
            display: flex;
            align-items: center;
            gap: 1.25rem; //20px;
            cursor: pointer;
            label {
              cursor: pointer;
              color: #000;
              font-family: Oxanium;
              font-size: 25px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.575px;
            }
            input {
              height: 1.875rem; //30px;
              width: 1.875rem; //30px;
              aspect-ratio: 1 / 1;
              background: #d9d9d9;
              border-radius: 50%;
              appearance: none;
              border: 0.3125rem solid #d9d9d9;
              cursor: pointer;
              transition: 0.3s;
              &:checked {
                background: #002f5d;
              }
            }
          }
        }
      }

      .fields {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 3.75rem; //60px;
        margin-left: 4.6875rem;
        margin-right: 4.6875rem;
        width: calc(100% - 4.6875rem * 2);
        .form_field {
          display: flex;
          align-items: flex-start;
          width: 100%;
          img {
            width: 2.8125rem; //45px;
          }
          .input_right {
            display: flex;
            flex-direction: column;
            gap: 0.625rem; //10px;
            padding-left: 1.25rem; //20px;
            width: 100%;
            & > label {
              font-weight: 700;
              font-size: 1.3125rem; //21px;
              cursor: pointer;
            }
            & > input {
              height: 2.5rem; //40px;
              width: 100%;
              border: 0;
              //border-bottom: 0.125rem solid #002f5d;
              border-bottom: 0.125rem solid #acacac;
              &::placeholder {
                font-size: 1.0625rem; //17px;
                color: #acacac;
              }
            }
            .select_container {
              height: 2.5rem; //40px;
              width: 100%;
              border: 0;
              //border-bottom: 0.125rem solid #002f5d;
              border-bottom: 0.125rem solid #acacac;
              position: relative;
              &::after {
                width: 1.875rem; //30px;
                height: 100%;
                @include toCenter();
                content: "\f078";
                font-family: "Font Awesome 6 Pro";
                color: #acacac;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.5625rem; //25px;
                pointer-events: none;
              }
              select {
                padding-right: 1.5625rem;
                padding-left: 0.625rem;
                appearance: none;
                border: 0;
                width: 100%;
                height: 100%;
                font-size: 1.0625rem; //17px;
                color: #acacac;
                option {
                  padding: 0.625rem; //10px;
                }
              }
            }
            .date_hour {
              display: flex;
              input {
                height: 2.5rem; //40px;
                border: 0;
                // border-bottom: 0.125rem solid #002f5d;
                border-bottom: 0.125rem solid #acacac;
                &::placeholder {
                  font-size: 1.0625rem; //17px;
                  color: #acacac;
                }
              }
              input:nth-child(1) {
                width: 60%;
              }
              span {
                width: 5%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                color: #acacac;
              }
              input:nth-child(3) {
                padding-left: 1.25rem; //20px;
                width: 35%;
              }
            }
          }
        }
      }
      .message_field {
        width: 100%;
        margin-top: 2.5rem; //40px;
        background: #f7f7f7;
        border: 0.0625rem solid #e3e3e3;
        padding: 1.25rem 1.75rem; //20px 28px;
        margin-left: 4.6875rem;
        margin-right: 4.6875rem;
        width: calc(100% - 4.6875rem * 2);
        display: flex;
        flex-direction: column;
        gap: 0.625rem; //10px;
        label {
          color: #6b6b6b;
          font-weight: 700;
          font-size: 1.333125rem; //21.33px;
        }
        textarea {
          width: 100%;
          border: 0;
          background: none;
          resize: none;
          min-height: 6.25rem; //100px;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem; //16px;
          line-height: 1.25rem; //20px;
          &::placeholder {
            color: #acacac;
          }
        }
      }
      button {
        width: 100%;
        height: 4.625rem; //3rem;
        margin-top: 2.5rem; //40px;
        border-radius: 0.5rem; //8px;
        background-color: #002f5d;
        color: white;
        font-size: 1.25rem; //21.33px;
        margin-left: 4.6875rem;
        margin-right: 4.6875rem;
        width: calc(100% - 4.6875rem * 2);
        margin-bottom: 2.8125rem; //45px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .rendez_vous {
    width: 90%;
    form {
      overflow: hidden;
      .fields {
        grid-template-columns: 1fr;
        .input_right {
          .date_hour {
            input {
              &::placeholder {
                font-size: 0.75rem !important; //12px !important;
              }
            }
          }
        }
      }
    }
    .form_wrapper {
      display: flex;
      flex-direction: column;
      .left_pane_info,
      #diagnostic_booking {
        width: 100%;
      }

      #diagnostic_booking {
        margin-top: 2rem;
        .square_radio_options,
        .fields,
        .message_field {
          margin: 0 0;
          padding: 0 1rem 1rem 1rem;
          width: 100%;
        }
        .message_field {
          padding: 0 1rem;
          border: 1rem solid #fff;
          border-top-color: #f7f7f7;
          border-bottom-color: #f7f7f7;
        }
        .options_container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.25rem;
          padding: 0 0;
          margin-bottom: 3.5rem;
        }
        .form_field {
          img {
            width: 2.5rem;
          }
          label {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

button.devisBtn {
  width: 100%;
  height: 3.625rem; //3rem;
  margin-top: 2.5rem; //40px;
  border-radius: 0.5rem; //8px;
  background-color: #002f5d;
  color: white;
  font-size: 1.25rem; //21.33px;
  &:hover {
    cursor: pointer;
  }
}

.input_right {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > label {
    font-weight: 700;
    font-size: 0.9rem; //21px;
    cursor: pointer;
    b {
      color: #eb0e0e;
    }
  }
  & > input {
    height: 2.5rem; //40px;
    width: 100%;
    border: 0;
    padding: 0 0.625rem; //0 10px;
    //border-bottom: 0.125rem solid #002f5d;
    border-bottom: 0.125rem solid #acacac;
    &::placeholder {
      font-size: 0.8rem; //17px;
      color: #acacac;
    }
  }
  .select_container {
    height: 2.5rem; //40px;
    width: 100%;
    border: 0;
    //border-bottom: 0.125rem solid #002f5d;
    border-bottom: 0.125rem solid #acacac;
    position: relative;
    &::after {
      width: 1.875rem; //30px;
      height: 100%;
      @include toCenter();
      content: '\f078';
      font-family: 'Font Awesome 6 Pro';
      color: #acacac;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.1rem; //25px;
      pointer-events: none;
    }
    select {
      padding-right: 1.5625rem;
      padding-left: 0.625rem;
      appearance: none;
      border: 0;
      width: 100%;
      height: 100%;
      font-size: 0.8rem; //17px;
      color: #acacac;
      option {
        padding: 0.625rem; //10px;
      }
    }
  }
  .date_hour {
    display: flex;
    input {
      height: 2.5rem; //40px;
      border: 0;
      // border-bottom: 0.125rem solid #002f5d;
      border-bottom: 0.125rem solid #acacac;
      &::placeholder {
        font-size: 1.0625rem; //17px;
        color: #acacac;
      }
    }
    input:nth-child(1) {
      width: 60%;
    }
    span {
      width: 5%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: #acacac;
    }
    input:nth-child(3) {
      padding-left: 1.25rem; //20px;
      width: 35%;
    }
  }
}

.bons_plans {
  padding: 1.25rem 5%; //20px 5%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem; //20px;
  background: #f7fafc;
  .subtitles {
    display: flex;
    gap: 0.2875rem;
    img {
      align-self: start;
      width: 2.0625rem; //33px;
      aspect-ratio: 1/1;
    }
    p {
      span {
        font-weight: 700;
        span {
          padding-left: 2.7rem;
        }
      }
      a {
        color: #1434cb;
        text-decoration: underline;
      }
    }
  }

  .bons_plans_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.875rem; //30px;
    .single_product {
      position: relative;
      background: white;
      padding: 2.1875rem 1.25rem; //35px 20px;
      @include toCenter();
      justify-content: space-between;
      min-height: 27.5rem !important; //440px !important;
      flex-direction: column;
      gap: 1.75rem; //28px;
      img {
        width: 6.25rem; //100px;
      }
      h3 {
        font-size: 1.3rem; //20px;
        font-weight: 400;
        text-align: center;
      }
      .packed_text {
        p {
          margin: 0;
          text-align: center;
          font-weight: 400;
          font-size: 1.25rem; //16px;
          line-height: 1.625rem; //26px;
        }
      }

      h4 {
        font-size: 18px;
        font-weight: 700;
        color: #07a717;
      }
      strong {
        font-size: 1.5625rem; //25px;
      }
      button {
        width: 80%;
        background: var(--main-color);
        border-radius: 0.375rem; //6px;
        height: 2.5rem; //40px;
        border: 0;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          padding-left: 0.625rem; //10px;
        }
        i {
          color: white;
          font-size: 1rem; //16px;
        }
      }
    }
  }

  .used_vehicles_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //grid-gap: 1.875rem; //30px;
    row-gap: 4.375rem; //70px;
    column-gap: 3.125rem; //50px;
    .single_product {
      position: relative;
      background: white;
      padding: 1.125rem 1.375rem; //18px 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 13.25rem !important; //212px !important;
      .image_container {
        padding-right: 0.625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 12.1875rem; //195px;
          height: 6.25rem; //100px;
          object-fit: cover;
        }
        h3 {
          font-size: 1.125rem; //18px;
          color: #eb0e0e;
          font-weight: 700;
        }
      }
      .description_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        h3 {
          color: #000;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.12px;
        }
        .packed_text {
          p {
            margin: 0;
            text-align: left;
            color: #000;
            font-weight: 400;
            font-size: 0.9375rem; //15px;
            line-height: 1.1875rem; //19px;
          }
        }
        h4 {
          font-size: 1.25rem; //20px;
          font-weight: 400;
        }
        strong {
          font-size: 1.25rem; //20px;
        }
      }
      .actions_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .infos {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          h3 {
            color: #000;
            font-family: Oxanium;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.132px;
            text-align: center;
          }
          @media screen and (max-width: 1024px) {
            h3 {
              width: 100%;
              text-align: center;
            }
          }
          h4 {
            font-size: 15px;
            font-weight: 700;
            color: #07a717;
          }
        }
        .actions {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 0.1875rem; //3px;
          button {
            width: 100%;
            background: var(--main-color);
            border-radius: 0.375rem; //6px;
            height: 1.9375rem; //31px;
            border: 0;
            color: white;
            font-size: 1rem;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              padding-left: 0.625rem; //10px;
            }
          }
          button.devis {
            background: var(--secondary-color);
          }
        }
      }
    }
  }

  .view_more {
    cursor: pointer;
    transition: 0.3s;
    @include toCenter();
    background: #f4f3f3;
    border: 0.0625rem solid #dde2e4;
    border-radius: 0.3125rem; //5px;
    height: 3.1875rem; //51px;
    width: 10.4375rem; //167px;
    font-weight: 700;
    font-size: 1.25rem; //20px;
    cursor: pointer;
    margin: {
      left: auto;
      right: auto;
    }
    &:hover {
      border-width: 0.25rem; //4px;
    }
  }
}
@media (max-width: 1024px) {
  .bons_plans {
    .bons_plans_container {
      grid-template-columns: 1fr;
    }
    .used_vehicles_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .single_product {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width: 22.75rem;
        width: 100%;

        .description_container {
          gap: 0.5rem;
          h4,
          strong,
          h3 {
            text-align: center;
            width: 100%;
          }
          .packed_text {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            p {
              text-align: center;
            }
          }
        }
        .actions_container {
          gap: 0.5rem;
          .infos,
          .actions {
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

.vehicle_details {
  padding: 1.25rem 5%; //20px 5%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem; //20px;
  background: #f7fafc;
  padding-bottom: 10rem;
  @media screen and (max-width: 1240px) {
    gap: 4rem;
  }
  img {
    width: 38px;
    height: 24px;
  }
  button {
    width: 236px;
    height: 23.385px;
    margin-left: 4rem;
    color: white;
    border-radius: 10px;
    border: none;
    background-color: var(--main-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
  }
  .vehicle {
    display: flex;
    justify-content: flex-end;
    gap: 0.69rem;

    .images {
      display: flex;
      gap: 1rem;
      flex: auto;
      @media screen and (min-width: 1024px) {
        margin-right: 0.69rem;
      }
      .thumbnails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        img {
          cursor: pointer;
          width: 130.506px;
          flex: 1;
          max-height: 98.352px;
        }
      }
      .selected_vehicule {
        display: flex;
        height: 600px;
        flex: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          //object-fit: contain;
        }
      }
    }

    .infos_actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 550px;
      width: 100%;
      gap: 2rem;

      > div {
        display: flex;
        flex: 1;
        flex-shrink: 0;
      }

      > div:last-child {
        flex-direction: column;
        justify-content: flex-end;
        gap: 1rem;

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: black;
        }

        > div {
          display: flex;
          justify-content: space-between;
          gap: 8rem;
          margin-top: 1rem;
          @media screen and (max-width: 1240px) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 2rem;
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .one_line {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;

          img {
            width: 33px;
            height: 29px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            @media (max-width: 1024px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      width: 250px;
      .top {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3 {
          font-size: 28px;
          font-weight: 700;
          color: #000;
        }
        p {
          font-size: 21px;
          line-height: 38px;
          font-weight: 400;
        }
      }
      h3 {
        font-size: 30px;
        font-weight: 700;
        color: var(--main-color);
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 250px;
      .top_wrapper {
        display: flex;
        flex-direction: column;
        button {
          width: 100%;
          margin: 0;
          min-width: 267px;
          height: 50px;
          background-color: var(--secondary-color);
          border-radius: 5px;
          color: white;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
          img {
            width: 15.84px;
            height: 7.098px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .expand_details {
          display: flex;
          flex-direction: column;
          background-color: white;
          padding-left: 17px;
          padding-bottom: 46px;
          gap: 10px;
          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            font-family: Plus Jakarta Sans;
            font-style: normal;
          }
          .one_line {
            display: flex;
            align-items: center;
            gap: 10px;
            img,
            i {
              width: 19.97px;
              height: 20px;
              color: var(--main-color);
              font-size: 19px;
            }
            p {
              font-size: 1rem;
              font-weight: 500;
              line-height: 42px;
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media screen and (min-width: 1240px) {
          transform: translateY(122px);
        }
        .one_line {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 33px;
            height: 29px;
          }
          p {
            font-size: 1rem;
            font-weight: 500;
            line-height: 42px;
            @media (max-width: 1024px) {
              font-size: 1.5rem;
            }
            span {
              text-decoration: underline;
              color: #0089ca;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1240px) {
      flex-direction: column;
      position: relative;
      .images {
        flex-direction: column-reverse;
        .thumbnails {
          flex-direction: row;
          overflow-x: auto;
          @media screen and (max-width: 1240px) {
            img {
              max-height: 120px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .actions {
        .top_wrapper {
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%;
          transform: translateX(-50%) translateY(-120%);
          .expand_details {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            background-color: #ffffffd7;
          }
        }
      }
    }
  }
}

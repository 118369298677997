:root {
  --main-color: #ec5b0d;
  --light-orange: #f7a034;
  --secondary-color: #002f5d;
}

dialog {
  overflow: hidden;
}

input:disabled {
  background-color: #9f9f9f14;
}

#root {
  width: 100%;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.orange {
  color: var(--light-orange) !important;
}

.bold {
  font-weight: bold !important;
}

.o_cgu {
  display: flex;
  align-items: center;
  gap: 0.625rem; //10px;
  font-size: 15px;
  font-weight: 600;

  input {
    width: 1rem;
    height: 1rem;
  }

  a {
    color: #0087c7;
    text-decoration: none;
  }
}

.quantity_control {
  display: flex;
  justify-content: center;
  > div {
    display: flex;
    span {
      font-family: 'Titillium Web';
      font-size: 1.5625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5625rem; /* 100% */
      letter-spacing: 0.0125rem;
      text-transform: lowercase;
      margin: 0 1.5rem;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6875rem;
      height: 1.6875rem;
      border-radius: 0.3125rem;
      border: none;
      background-color: #002f5d;
      margin: 0;
      padding: 0;
      cursor: pointer;
      svg {
        fill: #fff;
      }
      &:disabled {
        background: #cfe2f4;

        svg {
          fill: #000;
        }
      }
    }
  }
}

.bottom_details_mobile {
  display: flex;
  flex-direction: column;
  gap: 0.625rem; //10px;
  h3 {
    font-family: Titillium_Web;
  }

  .table {
    width: 100%;
    grid-column: 1 / 3;
    .row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.1875rem 0.625rem; //3px 10px;
      &.gray_row {
        background: #efefef;
        border: 0.0625rem solid #d5d5d5;
      }
      span {
        font-family: Titillium_Web;
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.user_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #f7fafc;
  border-radius: 50%;
  font-weight: bold;
  color: #002f5d;
  @media screen and (max-width: 1024px) {
    background-color: #002f5d;
    color: #f7fafc;
  }
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 14rem;
    padding: 0 0;
    background-color: #f7fafc;
    border-radius: 0.3125rem;
    z-index: 100;
    button,
    a {
      display: flex;
      gap: 1rem;
      width: 100%;
      padding: 0.625rem 1.25rem;
      text-align: left;
      font-size: 0.875rem;
      align-items: center;
      color: #002f5d;
      border: none;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        background-color: #002f5d;
        color: #fff;
      }
      &:hover i {
        color: #fff;
      }
      i {
        font-size: 1.5rem !important;
      }
    }
    button.user {
      background-color: #dcdcdc;
      color: #002f5d;
      padding-top: 1.2rem;
      font-weight: bold;
      font-size: 1rem;
      &:hover {
        background-color: #dcdcdc;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

button:disabled {
  cursor: not-allowed;
  background-color: #9f9f9f !important;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
}

input[aria-invalid='true'],
select[aria-invalid='true'] {
  border: 1px solid red !important;
  outline: none !important;
  box-shadow: none !important;
}

.not_found_page {
  text-align: center;
  margin: 3rem 0;

  .main-heading {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .gif {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.3rem;
    padding: 0.7rem 0;

    margin-bottom: 2rem;
  }
  a {
    color: var(--white, #fff);
  }

  button {
    padding: 0.69rem 3.92rem;
    border-radius: 3.75rem;
    background: #1e1e1e;
    color: var(--white, #fff);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    line-height: 1.5rem; /* 133.333% */
    cursor: pointer;
  }
}

/*----------------|
|      FONTS      |
|----------------*/
@font-face {
  font-family: Inter_Regular;
  src: url(../fonts/inter/static/inter-regular.ttf);
}
@font-face {
  font-family: Oxanium_Regular;
  src: url(../fonts/oxanium/static/oxanium-regular.ttf);
}
@font-face {
  font-family: IBM_Plex_Sans;
  src: url(../fonts/ibm_plex_sans/ibmplexsans-regular.ttf);
}
@font-face {
  font-family: Titillium_Web;
  src: url(../fonts/titillium_web/titilliumweb-regular.ttf);
}
@font-face {
  font-family: Titillium_Web_Bold;
  src: url(../fonts/titillium_web/titilliumweb-bold.ttf);
}
@font-face {
  font-family: Manrope;
  src: url(../fonts/Manrope/static/Manrope-Regular.ttf);
}
:root {
  --main-font: Oxanium_Regular;
  --secondary-font: Inter_Regular;
}

/*----------------|
|    Page Style   |
|----------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
  outline: none;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}
html {
  max-width: 2000px;
  margin: 0 auto;
}
body {
  overflow-x: hidden;
}
.darkened {
  background: #f7fafc;
}

.mobile_pagination_container {
  display: flex;
  justify-content: center;
  .mobile_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #dee2e7;
    border-radius: 0.55rem;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      color: #1c1c1c;
      font-size: 1.25rem;
      font-weight: 500;
      border-right: 1px solid #dee2e7;
      &:hover {
        background-color: #f7fafc;
      }
    }

    li.disabled a {
      color: #dee2e7;
      cursor: not-allowed;
    }

    li.previous a,
    li.next a {
      font-weight: 700;
      font-size: 1.5rem;
    }

    li.selected a {
      background-color: var(--main-color);
      color: #fff;
    }

    li:last-child a {
      border: none;
    }
  }
}
/*----------------|
|      Mixins     |
|----------------*/
@mixin toCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*----------------|
|      Classes    |
|----------------*/
*.hide {
  display: none !important;
}
*.main-color {
  color: var(--main-color);
}
*.fade_left {
  animation: fade_left 1s;
}
@media (min-width: 1024px) {
  .hide_desktop {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .hide_mobile {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hide_swipper {
    display: none !important;
  }
}
/*----------------|
|    Animations   |
|----------------*/
@keyframes fade_left {
  from {
    transform: translateX(10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/*-------------|
|    IMPORTS   |
|-------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Oxanium:wght@400;500;600;700&family=Plus+Jakarta+Sans:wght@400;500;600;700&family=Titillium+Web:wght@400;600;700&display=swap');
@import './global';
@import './pages/home';
@import './pages/about';
@import './pages/pieces_catalogue';
@import './pages/bons_plans';
@import './pages/validation';
@import './pages/commande';
@import './pages/cgu';
@import './pages/_orange_payment_page';

.pieces_images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  img:first-child {
    width: 100%;
    height: 19px;
    object-fit: contain;
  }
  span {
    color: #858080;
    font-family: Oxanium;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: -0.00563rem;
    text-transform: lowercase;
  }
}
.devis_button {
  color: #fff;
  font-family: Titillium Web;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625rem; /* 100% */
  letter-spacing: -0.00938rem;
  background: #002f5d;
  cursor: pointer;
}
.compatible_piece {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #07a717;
  font-family: Oxanium;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0075rem;
  @media screen and (max-width: 1024px) {
    display: inline;
  }
  svg {
    width: 1.6875rem;
    height: 1.6875rem;
    @media screen and (max-width: 1024px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
header {
  & > div {
    width: 100%;
    padding: 0 5%;
  }
  .header_top {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: space-between;
    // gap: 3.125rem;
    height: 7.1875rem;
    .menu_container {
      height: inherit;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;
      .menu_icon {
        @include toCenter();
        background: var(--main-color);
        width: fit-content;
        height: 1.875rem;
        width: 1.875rem;
        aspect-ratio: 1 / 1;
        border-radius: 0.3125rem;
        cursor: pointer;
        user-select: none;
        img {
          width: 1.125rem;
        }
      }
      &::after {
        content: none;
        box-sizing: border-box;
        color: #000;
        font-size: 1.5625rem; // 25px;
        font-family: Oxanium;
        font-style: normal;
        background: #dcdcdc;
        position: absolute;
        left: 0;
        top: 100%;
        //width: 22.875rem; //366px;
        width: 24rem; //366px;
        height: 3.9375rem; //63px;
        padding-left: 2.3125rem; //37px;
        display: flex;
        align-items: center;
      }

      &.show::after {
        content: 'MENU';
        box-sizing: border-box;
        color: #000;
        font-size: 1.5625rem; // 25px;
        font-family: Oxanium;
        font-weight: 600;
        font-style: normal;
        background: #dcdcdc;
        position: absolute;
        left: 0;
        top: 100%;
        //width: 22.875rem; //366px;
        width: 24rem; //366px;
        height: 3.9375rem; //63px;
        padding-left: 2.3125rem; //37px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .menu_mobile {
        display: flex;
        width: calc(100vw - 10rem);
        position: absolute;
        z-index: 11;
        left: 0;
        top: 100%;
        border: 1px solid #dcdcdc;
        animation: fade_left 0.4s;
        background-color: white;

        @media screen and (max-width: 524px) {
          width: calc(100vw - 2rem);
        }

        .menu_mobile_list {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        &_1,
        &_2,
        &_3,
        &_4 {
          width: 100%;
          h4 {
            display: flex;
            align-items: center;
            gap: 1rem;
            box-sizing: border-box;
            color: #000;
            font-size: 1.5625rem; // 25px;
            font-family: Oxanium;
            font-weight: 600;
            font-style: normal;
            background: #dcdcdc;
            //width: 22.875rem; //366px;
            width: 100%; //366px;
            height: 3.9375rem; //63px;
            padding-left: 2.3125rem; //37px;
            display: flex;
            align-items: center;
            i {
              cursor: pointer;
            }
          }
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #002f5d;
            font-size: 1.25rem;
            font-weight: bold;
            text-transform: uppercase;
            padding: 1rem 2.3rem;
          }

          .mobile_submenu_container {
            display: flex;
            flex-direction: column;
            padding: 0 0.5rem;
            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #3a3a3a;
              font-family: Oxanium;
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.0125rem;
              padding: 0.3rem 2.3rem;
            }
          }
        }
      }
      .menu {
        display: flex;
        width: 55rem;
        max-width: calc(100vw - 2.5rem);
        position: absolute;
        z-index: 11;
        left: 0;
        top: calc(100% + 3.9375rem);
        border: 1px solid #dcdcdc;
        animation: fade_left 0.4s;
        background-color: white;
        .menu_list {
          display: flex;
          flex-direction: column;
          flex-basis: 24rem;
          flex-grow: 0;
          flex-shrink: 0;
          gap: 1.5rem;
          padding: 1.0625rem 1.5rem 1.0625rem 2.3125rem;
          background: white;
          border: 1px solid #dcdcdc;
          animation: fade_left 0.4s;
          .empty_submenu {
            background: rgb(255, 163, 163);
            color: red;
            width: 100%;
            border-radius: 0.3125rem;
            padding: 0.625rem 1.25rem;
          }
          .list_title {
            color: #002f5d;
            font-size: 1.25rem;
            font-weight: bold;
            text-transform: uppercase;
          }
          .submenu_container {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .submenu {
              font-size: 1.0625rem;
              color: black;
              width: 100%;
              height: 1.5rem;
              flex-shrink: 0;

              position: relative;
              // &::after {
              //   content: "\f054";
              //   font-family: "Font Awesome 6 Pro";
              //   position: absolute;
              //   //right: 0;
              //   right: 0;
              //   top: 50%;
              //   transform: translateY(-50%);
              //   transition: 0.3s;
              // }
              &.active_link,
              &:hover {
                color: var(--main-color);
              }
              &:hover::after {
                right: 0.3125rem;
              }
            }
          }
          .submenu_content {
            font-weight: 400;
            color: black;
            transition: 0.3s;
            &:hover {
              color: var(--main-color);
            }
          }
        }
        .mid_level {
          display: flex;
          flex-direction: column;
          flex-basis: 307px;
          gap: 1.5rem;
          border: 1px solid #dcdcdc;
          padding: 1.0625rem 1.5625rem;
          background: white;
          animation: fade_left 0.4s;
          .empty_submenu {
            background: rgb(255, 163, 163);
            color: red;
            width: 100%;
            border-radius: 0.3125rem;
            padding: 0.625rem 1.25rem;
          }
          .submenu {
            font-size: 1.0625rem;
            color: black;
            width: 100%;
            position: relative;
            // &::after {
            //   content: "\f054";
            //   font-family: "Font Awesome 6 Pro";
            //   position: absolute;
            //   right: 0;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   transition: 0.3s;
            // }
            &.active_link,
            &:hover {
              color: var(--main-color);
            }
            &:hover::after {
              right: 0.3125rem;
            }
          }
          .submenu_content {
            font-weight: 400;
            color: black;
            transition: 0.3s;
            &:hover {
              color: var(--main-color);
            }
          }
        }
        .leaf_level {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding: 1.0625rem 1.5625rem;
          flex-basis: 310px;
          background: white;
          animation: fade_left 0.4s;
          .submenu_content {
            font-weight: 400;
            color: black;
            transition: 0.3s;
            &:hover {
              color: var(--main-color);
            }
          }
        }
      }
    }
    .search_field {
      display: flex;
      align-items: center;
      padding: 0.125rem;
      background: #d3d3d3;
      border-radius: 1.25rem;
      position: relative;
      input {
        background: white;
        border: 0;
        border-radius: inherit;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 3.125rem;
        width: 25rem;
        padding: 0.3125rem 0.9375rem;
        font-size: 0.875rem;
        color: black;
        &::placeholder {
          color: #cac7c7;
        }
      }
      button {
        @include toCenter();
        width: 4.375rem;
        font-size: 1.25rem;
        height: 3.15rem;
        background: none !important;
        border: none;
        cursor: pointer;
      }
      .res_container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        border-radius: 0 0 0.625rem 0.625rem;
        z-index: 50;
        overflow: hidden;
        a {
          padding: 0.6rem 1rem;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          > div:nth-child(2) {
            font-size: 14px;
            color: #7a7a7a;
          }
          &:hover {
            background: #dcdcdc;
          }
        }
      }
    }
    .contact_details {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      .detail {
        display: flex;
        align-items: center;
        gap: 0.6875rem;
        font-size: 1rem;
        color: #3a3a3a;
      }
    }
    .menu_button {
      @include toCenter();
      text-align: center;
      background: var(--main-color);
      padding: 0.8125rem 1rem 0.8125rem 1rem;
      border-width: 0;
      font-weight: 700;
      border-radius: 0.625rem;
      color: white;
      font-size: 0.875rem;
      // max-width: 7.8125rem;
      max-width: 12rem;
      cursor: pointer;
    }
  }
  .header_bottom {
    background: #002f5d;
    height: 5.513125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ca_icon {
      width: 1.875rem;
      &:hover {
        cursor: pointer;
      }
    }
    .select_vehicule {
      display: flex;
      display: flex;
      align-items: center;
      gap: 0.9375rem;
      strong {
        color: white;
        text-transform: uppercase;
      }
      form {
        display: flex;
        align-items: center;
        gap: inherit;
        height: 2.3125rem;
        .select_container {
          position: relative;
          border-radius: 0.375rem;
          background: white;
          select {
            padding-right: 1.5625rem;
            padding-left: 0.625rem;
            option {
              color: white;
            }
          }
          #header_brand {
            width: 130px;
            max-width: 130px;
          }
          #header_model {
            width: 110px;
            max-width: 110px;
          }
          #header_drive {
            width: 150px;
            max-width: 150px;
          }

          @media screen and (max-width: 1024px) {
            #header_brand {
              width: 100%;
              max-width: 125px;
            }
            #header_model {
              width: 100%;
              max-width: 110px;
            }
            #header_drive {
              width: 100%;
              max-width: 150px;
            }
          }
          &::after {
            @include toCenter();
            font-family: 'Font Awesome 6 Pro';
            content: '\f078';
            top: 50%;
            width: 1.25rem;
            right: 0.3125rem;
            transform: translateY(-50%);
            position: absolute;
            pointer-events: none;
            font-size: 0.6875rem;
          }
        }
        select {
          height: 2.3125rem;
          border-width: 0;
          border-radius: inherit;
          appearance: none;
          background: transparent;
          cursor: inherit;
          color: black;
          text-transform: uppercase;
          option {
            background: #002f5d;
            text-transform: uppercase;
          }
        }
        .active-select {
          background: var(--main-color);
          select {
            color: white;
          }
          &::before {
            color: #fff;
          }
          &::after {
            color: #fff;
          }
        }
        .button_bars {
          position: relative;
          border-radius: 0.375rem;
          cursor: pointer;
          min-width: 7.3125rem;
          select {
            padding-left: 2.5rem;
            padding-right: 1.5rem;
            width: 100%;
          }
          &::before {
            @include toCenter();
            font-family: 'Font Awesome 6 Pro';
            content: '\f0c9';
            top: 50%;
            width: 2.5rem;
            left: 0;
            transform: translateY(-50%);
            position: absolute;
            pointer-events: none;
          }
        }
        button {
          background: #002e51;
          border: 0.0625rem solid #dfdfdf;
          color: white;
          border-radius: 0.3125rem;
          height: 100%;
          font-family: Inter_Regular;
          padding: 0 0.9375rem;
          cursor: pointer;
        }

        button.active-select {
          border: none;
          &::after {
            display: none;
          }
        }
      }
    }
    .icon_list {
      display: flex;
      align-items: center;
      gap: 1.875rem;
      li {
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        align-items: center;
        gap: 0.8125rem;
        color: #b0babf;
        font-size: 0.875rem;
        position: relative;
        a {
          display: flex;
          align-items: center;
          color: #b0babf;
          gap: 0.8125rem;
          &:hover {
            color: white;
          }
        }
        &:hover {
          color: white;
        }
        .notification,
        .order_notification,
        .likes_notification {
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          right: 0;
          top: -15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          //transform: translateY(-50%);
          z-index: 20;
          background-color: white;
          color: #003b76;
        }
      }
    }
    .user_link {
      i {
        color: white;
        font-size: 1.9375rem;
        transition: 0.3s;
        pointer-events: none;
      }
      &:hover i {
        color: var(--main-color);
      }
    }
  }
}
@media (max-width: 1024px) {
  header {
    max-width: 100vw;
    & > div {
      padding: 2.5rem 4%;
      max-width: 100vw;
    }
    .header_top {
      gap: unset;
      height: auto;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: unset;
      padding: 1.25rem 1.25rem;
      gap: 0;
      .contact_details {
        order: 0;
        width: 70%;
        .detail {
          font-size: 0.875rem;
        }
      }
      .menu_button {
        order: 1;
        min-width: 30% !important;
        max-width: 30% !important;
        padding: 0.5rem 0.375rem;
        font-size: 0.8125rem;
        margin-left: auto;
      }
      .menu_container {
        order: 3;
        .menu {
          top: calc(100% + 3.7rem);
        }
      }
      .logo {
        order: 4;
        max-width: 8.625rem;
        width: 100%;
        margin: {
          top: 1.875rem;
          bottom: 1.875rem;
          left: 0.875rem;
        }
      }
      .user_link {
        order: 5;
        margin-left: auto;
        i {
          font-size: 2.25rem;
          color: #002f5d;
        }
      }
      .search_field {
        order: 6;
        max-width: 100%;
        min-width: 100%;
        display: flex;
        input {
          max-width: 80%;
          min-width: 80%;
          &::placeholder {
            font-size: 0.875rem;
          }
        }
        button {
          width: 20%;
        }
      }
    }
    .header_bottom {
      gap: unset;
      height: auto;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: unset;
      padding: 1.25rem 1%;
      gap: 0;
      .select_vehicule {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        max-width: fit-content;
        form {
          gap: 0.3125rem;
          justify-content: space-around;
          width: 100%;
          .select_container {
            select {
              padding-right: 0.9375rem;
              padding-left: 0.625rem;
              font-size: 0.75rem;
            }
            &::after {
              width: 0.625rem;
              right: 0.1875rem;
              font-size: 0.625rem;
            }
          }
          .button_bars {
            min-width: 5.875rem;
            select {
              padding-left: 1.25rem;
              padding-right: 0.625rem;
              width: 100%;
            }
            &::before {
              width: 1.25rem;
              font-size: 0.9375rem;
            }
          }
          button {
            padding: 0 0.625rem;
          }
        }
      }
    }
  }
}

.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.515);
  backdrop-filter: blur(0.05rem);
  z-index: 99;
  height: 100vh;
  width: 100%;
  &#phone_login .popup form > label {
    margin-bottom: 1.25rem;
  }
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 41.0625rem;
    max-height: 95vh;
    padding: 2.5rem 6%;
    border-radius: 2.5rem;
    background: white;
    @include toCenter();
    flex-direction: column;
    gap: 1.25rem;
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .elm {
        margin-bottom: 1.25rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.375rem;
        label {
          overflow: hidden;
          color: #191d23;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Manrope;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0.3125rem;
          width: 100%;
          text-align: left;
          span {
            color: red;
          }
        }
        .icon_input {
          position: relative;
          input {
            width: 100%;
          }
          img {
            position: absolute;
            width: 1.5625rem;
            height: 1.5625rem;
            object-fit: contain;
            top: 50%;
            right: 0.625rem;
            transform: translateY(-50%);
          }
        }
        input {
          display: inline-flex;
          height: 2.5rem;
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          border: 0.09375rem solid #64748b;
          cursor: pointer;
        }
      }
      & > label {
        overflow: hidden;
        color: #191d23;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0.3125rem;
        width: 100%;
        text-align: left;
      }
      & > button {
        @include toCenter();
        border-radius: 0.25rem;
        background: #002f5d;
        border-width: 0;
        color: white;
        width: 100%;
        height: 2.8125rem;
        padding: 0.375rem 0.75rem;
        gap: 0.25rem;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 1.25rem;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background: #003b76;
        }
      }
      & > input {
        width: 100%;
      }
      .inline_inputs {
        width: 100%;
        display: flex;
        gap: 0.625rem;
        select,
        input {
          width: 30%;
          height: 2.5rem;
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          border: 0.09375rem solid #64748b;
          cursor: pointer;
        }
        input {
          width: 70%;
        }
      }
      & > .singled_input {
        width: 100%;
        height: 2.5rem !important;
        padding: 0.5rem 0.75rem;
        border-radius: 0.25rem;
        border: 0.09375rem solid #64748b;
      }
      .stay_connected_check {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 2.1875rem;
        gap: 0.1875rem;
        input,
        label {
          cursor: pointer;
        }
        input {
          accent-color: var(--secondary-color);
          height: 1.0625rem;
          width: 1.0625rem;
        }
      }
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: Manrope;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      max-width: 100%;
      color: #191d23;
      text-align: center;
      font-family: Manrope;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 1.25rem;
      a {
        color: var(--secondary-color);
        font-family: Manrope;
        font-weight: 600;
      }
    }
  }
  .popup.added_to_cart,
  .popup.sent_pi,
  .popup.sent_booking {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 41.0625rem;
    max-height: 95vh;
    padding: 2.5rem 6%;
    border-radius: 2.5rem;
    background: white;
    @include toCenter();
    flex-direction: column;
    gap: 1.25rem;
    min-width: 30%;
    i {
      font-size: 3.125rem;
      color: #07a717;
    }
    h4 {
      color: #4d4040;
      text-align: center;
      font-family: Oxanium_Regular;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .page_select {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      padding: 3rem 0;
      &_option {
        display: flex;
        gap: 1.4rem;
        color: #1d0c0c;
        font-family: Oxanium;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.0075rem;
        width: 100%;
        max-width: 70%;
        input[type='radio'] {
          display: none;
          width: 0;
          height: 0;
        }

        input[type='radio'] + label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          position: relative;
          padding-left: 4.5rem;
          cursor: pointer;
          font-size: 1.25rem;
          font-family: Oxanium;
          font-weight: 700;
          color: #1d0c0c;
          line-height: 1.5rem;
          letter-spacing: -0.0075rem;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            font-family: 'Font Awesome 6 Pro';
            font-weight: 900;
            font-size: 60px;
            color: #07a717;
            top: 50%;
            transform: translateY(-50%);
            width: 2.875rem;
            height: 2.375rem;
            border-radius: 0.3125rem;
            border: 4px solid #002f5d;
            background: transparent;
          }
        }

        input[type='radio']:checked + label {
          &:before {
            content: '\f00c';
          }
        }
      }
    }
    .buttons {
      justify-content: space-between;
      width: 100%;
      display: flex;
      gap: 1rem;
      button {
        width: 100%;
        flex: auto;
        cursor: pointer;
        @include toCenter();
        height: 2.9375rem;
        color: white;
        border-radius: 0.3125rem;
        background-color: #eb550e;
        transition: 0.14s;
        border: none;
        font-size: 1.7rem;
        max-width: 200px;
        padding: 0.5rem 1.25rem;
        &.button_empty {
          background-color: transparent !important;
          color: #333;
          border: 0.0625rem solid #333;
        }
        &:hover {
          padding-bottom: 0.25rem;
        }

        @media screen and (max-width: 420px) {
          font-size: 1.3rem;
          padding: 0.5rem 0.75rem;
        }
      }
    }
  }
}

.dialog_container {
  top: 50%;
  translate: 0 -50%;
  max-width: 41.0625rem;
  max-height: 95vh;
  border-radius: 2.5rem;
  animation: none;
  z-index: 100;
  &::backdrop {
    background: rgba(0, 0, 0, 0.93);
  }
  &#phone_login .popup form > label {
    margin-bottom: 1.25rem;
  }
  .popup {
    border-radius: 2.5rem;
    padding: 2.5rem 6%;
    background: white;
    @include toCenter();
    flex-direction: column;
    gap: 1.25rem;
    @media screen and (max-width: 426px) {
      h3 {
        font-size: 1.5rem !important;
      }
      .btn_cart {
        button {
          padding: 0.5rem !important;
        }
      }
    }
    .form_rep {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .elm {
        margin-bottom: 1.25rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.375rem;
        label {
          overflow: hidden;
          color: #191d23;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Manrope;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0.3125rem;
          width: 100%;
          text-align: left;
          span {
            color: red;
          }
        }
        .icon_input {
          position: relative;
          input {
            width: 100%;
          }
          img {
            position: absolute;
            width: 1.5625rem;
            height: 1.5625rem;
            object-fit: contain;
            top: 50%;
            right: 0.625rem;
            transform: translateY(-50%);
          }
        }
        input {
          display: inline-flex;
          height: 2.5rem;
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          border: 0.09375rem solid #64748b;
          cursor: pointer;
        }
      }
      & > label {
        overflow: hidden;
        color: #191d23;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0.3125rem;
        width: 100%;
        text-align: left;
      }
      & > button {
        @include toCenter();
        border-radius: 0.25rem;
        background: #002f5d;
        border-width: 0;
        color: white;
        width: 100%;
        height: 2.8125rem;
        padding: 0.375rem 0.75rem;
        gap: 0.25rem;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 1.25rem;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background: #003b76;
        }
      }
      & > input {
        width: 100%;
      }
      .inline_inputs {
        width: 100%;
        display: flex;
        gap: 0.625rem;
        select,
        input {
          width: 30%;
          height: 2.5rem;
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          border: 0.09375rem solid #64748b;
          cursor: pointer;
        }
        input {
          width: 70%;
        }
      }
      .stay_connected_check {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 2.1875rem;
        gap: 0.1875rem;
        input,
        label {
          cursor: pointer;
        }
        input {
          accent-color: var(--secondary-color);
          height: 1.0625rem;
          width: 1.0625rem;
        }
      }
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: Manrope;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      max-width: 100%;
      color: #191d23;
      text-align: center;
      font-family: Manrope;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 1.25rem;
      a {
        color: var(--secondary-color);
        font-family: Manrope;
        font-weight: 600;
      }
    }
  }

  .popup.user_created,
  .popup.user_not_created {
    i {
      font-size: 3.125rem;
      color: #07a717;
    }
    h4 {
      color: #4d4040;
      text-align: center;
      font-family: Oxanium_Regular;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .buttons {
      width: 100%;
      display: flex;
      gap: 0.625rem;
      a,
      button {
        width: 100%;
        cursor: pointer;
        @include toCenter();
        height: 2.9375rem;
        color: white;
        border-radius: 0.3125rem;
        background-color: #eb550e;
        transition: 0.14s;
        border: none;
        &.button_empty {
          background-color: transparent !important;
          color: #333;
          border: 0.0625rem solid #333;
        }
        &:hover {
          padding-bottom: 0.25rem;
        }
      }
    }
  }

  .popup.added_to_cart,
  .popup.sent_pi,
  .popup.sent_booking {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 41.0625rem;
    max-height: 95vh;
    padding: 2.5rem 6%;
    border-radius: 2.5rem;
    background: white;
    @include toCenter();
    flex-direction: column;
    gap: 1.25rem;
    min-width: 30%;
    i {
      font-size: 3.125rem;
      color: #07a717;
    }
    h4 {
      color: #4d4040;
      text-align: center;
      font-family: Oxanium_Regular;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .buttons {
      width: 100%;
      display: flex;
      gap: 0.625rem;
      a {
        width: 100%;
        cursor: pointer;
        @include toCenter();
        height: 2.9375rem;
        color: white;
        border-radius: 0.3125rem;
        background-color: #eb550e;
        transition: 0.14s;
        &.button_empty {
          background-color: transparent !important;
          color: #333;
          border: 0.0625rem solid #333;
        }
        &:hover {
          padding-bottom: 0.25rem;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .popup_overlay {
    .popup {
      width: 90%;
      border-radius: 1.25rem;
      h3 {
        font-size: 1.125rem;
      }
      p {
        font-size: 0.75rem;
      }
      form {
        .inline_inputs {
          select {
            padding-right: 0;
            padding-left: 0.3125rem;
          }
        }
        .stay_connected_check {
          margin-top: 0.9375rem;
        }
      }
    }
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  height: auto;
  @media screen and (max-width: 580px) {
    flex-direction: column;
    > div {
      align-items: center;
      width: 100%;
      span {
        flex: auto;
        select {
          width: 100% !important;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: transparent;
          background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
          background-repeat: no-repeat;
          background-position-x: 100%;
          background-position-y: 5px;
          background-size: 15%;
          border: 1px solid #dfdfdf !important;
          border-radius: 2px;
          margin-right: 2rem;
          padding: 1rem;
          padding-right: 2rem;
        }
      }

      span.ct {
        margin-left: 37px;
      }
    }
    i {
      display: none;
    }
  }
  & > i {
    color: #b7b7b7;
    font-size: 1.4375rem;
    font-weight: light;
  }
  .single_breadcrumb {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    position: relative;
    img {
      width: 2rem;
      height: 1.625rem;
      object-fit: contain;
    }
    span {
      display: flex;
      align-items: center;
      font-family: Titillium_Web;
      gap: 0.625rem;
      font-size: 1.25rem;
      select {
        display: flex;
        align-items: center;
        font-family: Titillium_Web;
        gap: 0.625rem;
        font-size: 1.25rem;
        border: none;
        background-color: transparent;
      }
    }
    .menu {
      position: absolute;
      top: 100%;
      left: 0;
      //width: 100%;
      z-index: 11;
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border: 1px solid #dcdcdc;
      padding: 10px 10px;
      & > .menu_item {
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
@media (max-width: 1024px) {
  .breadcrumb {
    gap: 0.3125rem;
    & > i {
      font-size: 1.0625rem;
    }
    .single_breadcrumb {
      gap: 0.3125rem;
      span {
        gap: 0.0625rem;
        font-size: 0.75rem;
      }
    }
  }
}

.website_navigation {
  padding: 1.25rem 5%;
  width: 100%;
  .website_navigation_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #002f5d;
    .second_hand_switcher {
      position: absolute;
      right: 0;
      top: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      background: #e4e9ef;
      a {
        display: flex;
        align-items: center;
        color: #000;
        font-family: Oxanium;
        font-size: 20px;
        font-style: normal;
        line-height: 23px;
        letter-spacing: -0.12px;
        height: calc((84px - 1px) / 2);
        padding-left: 40px;
        padding-right: 40px;
        border-bottom: 2px solid white;
        &.active {
          font-weight: 700;
        }
        &:last-child {
          border: none;
        }
      }
    }
    .nav_item {
      width: calc(20% - 1.25rem);
      height: 3.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 0.3125rem;
      font-size: 20px;
      color: white;
      cursor: pointer;
      transition: 0.2s;
      min-width: fit-content;
      &.active {
        text-decoration: underline;
        font-weight: 700;
        text-decoration-thickness: 3px;
        text-decoration-color: var(--main-color);
        text-underline-offset: 5px;
      }
      &:hover {
        padding-left: 0.625rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .website_navigation {
    padding: 1.25rem 0;
    width: 90%;
    margin: 0 auto;
    .website_navigation_wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      background: #002f5d;
      padding: 2.5rem 1.25rem;
      width: 100%;
      &::-webkit-scrollbar {
        display: none !important;
      }
      .nav_item {
        padding: 0;
        padding-bottom: 0.625rem;
        height: fit-content;
        text-align: left;
        justify-content: flex-start;
        white-space: nowrap;
        min-width: unset;
        width: unset;
        border-radius: 0;
        border-bottom: 0.0625rem solid white;
        &.active {
          background-color: transparent;
          border-bottom: 0.3125rem solid var(--main-color);
          text-decoration: none;
        }
        &:hover {
          padding-left: 1.25rem;
        }
      }
    }
  }
}

footer {
  padding-bottom: 0;
  .footer_top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6.25rem;
    background: #002f5d;
    height: 8.1875rem;
    position: relative;
    h2 {
      text-align: center;
      color: white;
      font-size: 3.4375rem;
      font-family: Inter_Regular;
      font-weight: 400;
    }
    img {
      position: absolute;
      left: 5%;
      top: 50%;
      width: 5.75rem;
      height: 5.25rem;
      object-fit: contain;
      transform: translateY(-50%);
    }
  }
  .footer_branding {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 3.125rem 5%;
    //background: white;
    background: #f5f5f5;
    img {
      width: 12.6875rem; // default 370px 23.125rem;
      max-width: 80%;
    }
    .social_icons {
      display: flex;
      align-items: center;
      gap: 2.1875rem;
      a {
        cursor: pointer;
        &:hover {
          i {
            transition: 0.3s;
            color: var(--main-color);
          }
        }
        i {
          color: #808080;
          font-size: 1.5rem;
        }
      }
    }
  }
  .footer_links {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    padding: 1.875rem 5%;
    justify-content: space-between;
    gap: 15%;
    //background: white;
    background: #f5f5f5;
    .links_col {
      display: flex;
      flex-direction: column;
      //flex-basis: 0;
      //flex-grow: 1;
      gap: 1.25rem;
      h4 {
        color: #29698f;
        font-size: 1.5625rem;
        font-family: Oxanium;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
      }

      & > a {
        color: #3a3a3a;
        font-size: 1.25rem;
        font-weight: 400;
        font-family: Oxanium;
        // line-height: 50px;
        text-transform: capitalize;
        position: relative;
        transition: 0.3s;
        &:hover {
          padding-left: 0.3125rem;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      & > a.email {
        text-transform: none !important;
      }
    }

    .links_col_center {
      display: flex;
      flex-direction: column;
      flex-basis: 25%;
      //flex-grow: 0;
      // padding-left: auto;
      gap: 1.25rem;
      //justify-self: center;
      h4 {
        color: #29698f;
        font-size: 1.5625rem;
        font-family: Oxanium;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
      }
      .media_container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.4375rem; //0.625rem;
        // width: 70%;
        a {
          //width: 100%;
          width: 2.75rem; //44.261px;
          height: 2.75rem; //44.261px;

          border-radius: 0.625rem;
          transition: 0.3s;
          line-height: 3.125rem;
          &:hover {
            transform: translateY(-0.1875rem);
          }
          img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: contain;
            border-radius: 0.625rem;
            &.gray_bg {
              background: #dfdfdf;
            }
          }
        }
      }
      & > a {
        color: #3a3a3a;
        font-size: 1.25rem;
        font-weight: 400;
        font-family: Oxanium;
        // line-height: 50px;
        text-transform: capitalize;
        position: relative;
        transition: 0.3s;
        &:hover {
          padding-left: 0.3125rem;
        }
      }
    }
  }
  .footer_copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    background: #ffffff;
    padding: 1.25rem 5%;
    p {
      font-weight: 400;
      font-size: 1.25rem;
      color: #3a3a3a80;
    }
  }
}
@media (max-width: 1024px) {
  footer {
    .footer_top {
      h2 {
        font-size: 1.5625rem;
      }
    }
    .footer_branding {
      padding: 3.125rem 3%;
      align-items: flex-end;
      img {
        width: 12.8125rem;
      }
      .social_icons {
        gap: 1.25rem;
        a {
          i {
            font-size: 1.125rem;
          }
        }
      }
    }
    .footer_links {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .footer_copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.25rem;
      //background: #f5fbff;
      background: #ffffff;
      padding: 1.25rem 3%;
      p {
        font-size: 1.0625rem;
      }
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  margin: 1rem;
  width: 48px;
  height: 48px;
  border: 5px solid var(--main-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.customEnterModalAnimation {
  animation: 300ms ease 0s 1 normal none running modal-in;
}

.customLeaveModalAnimation {
  animation: 300ms ease 0s 1 normal none running modal-out;
}

@keyframes modal-in {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes modal-out {
  0% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0.9;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

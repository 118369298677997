.orange_payment_page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  gap: 1.8rem;
  background-color: #f5f5f5;

  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: #000;
      text-decoration: none;
    }
  }

  .qr_code {
    width: 205px;
    height: 205px;
    background-color: #fff;
    border-radius: 5%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5%;
    }
  }

  h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    img {
      width: 64px;
    }
  }

  .deep_link {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8rem 4rem;
      width: 100%;
      height: 3rem;
      background-color: #e4682e;
      border-radius: 0.3125rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #000;
      border: none;
      cursor: pointer;
      text-align: center;

      .maxit {
        font-weight: 700;
        text-transform: capitalize;
        color: #fff;
        b:last-child {
          color: #000;
        }
      }
    }
  }
}

td {
  border: 1px solid #a8a8a7;
  padding: 0.5rem;
}
th {
  border: 1px solid #a8a8a7;
}
tr {
  text-align: center;
}
.commande_page_container {
  background: #f7fafc;
  margin-bottom: 4.19rem;
  padding: 0 0.5rem;
  @media screen and (min-width: 1024px) {
    padding: 0 2rem;
  }
  .logout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 2rem 0;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.62rem;
      border-radius: 0.3125rem;
      border: none;
      background: #fff;
      box-shadow: 1px 1px 3px 0px rgba(227, 227, 227, 0.9), -1px -1px 2px 0px rgba(194, 194, 194, 0.9),
        1px -1px 2px 0px rgba(227, 227, 227, 0.2), -1px 1px 2px 0px rgba(227, 227, 227, 0.2);
      padding: 0.5rem 1rem;
      color: #000;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Manrope;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }
  }
  .order_mobile {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .order_mobile_item {
      margin-top: 1.5rem;
      background: #fff;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .top {
        display: flex;
        justify-content: space-between;
        > div:first-child {
          margin-left: 0.62rem;
          text-align: left;
          display: flex;
          flex-direction: column;
          p:nth-child(1) {
            color: #000;
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: uppercase;
          }
          p:nth-child(2) {
            color: #5e5e5e;
            font-family: 'Titillium Web';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: uppercase;
          }
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: #fff;
            text-align: center;
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
            border-radius: 0.3125rem;
            background: #07a717;
            padding: 0.2rem 1rem;
            margin-left: 2.31rem;
          }
          span {
            margin-left: 0.66rem;
            cursor: pointer;
          }
        }
      }

      .middle {
        display: flex;
        gap: 3.31rem;
        flex-direction: column;
        margin-top: 2rem;
        .images {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img:nth-child(1) {
            margin-bottom: 0.62rem;
            width: 4.75rem;
            height: auto;
          }
          img:nth-child(2) {
            width: 4.75rem;
            height: auto;
          }

        }
        > div {
          display: flex;
          gap: 3rem;
          .infos {
            display: flex;
            flex-direction: column;
          }
          p {
            text-align: start;
          }
          p:nth-child(1) {
            color: #000;
            font-family: Oxanium;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 171.429% */
            letter-spacing: -0.00525rem;
            text-transform: uppercase;
          }
          p:nth-child(2) {
            color: #000;
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
          }
          p:nth-child(3) {
            color: #5e5e5e;
            font-family: 'Titillium Web';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
            span {
              display: block;
              color: #eb550e;
              font-family: 'Titillium Web';
              text-align: start;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.0125rem;
              text-transform: uppercase;
            }
          }
        }

        .qte {
          margin-top: 1.38rem;
          font-weight: 700;
          text-transform: capitalize;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #000;
        margin-top: 1rem;
        padding: 1rem 0;
        font-weight: 600;
      }
    }
  }
  .menu-btn {
    position: relative;
    ul {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 2rem;
      right: 0;
      background: #ececec;
      min-width: 10rem;
      border: 1px solid #a8a8a7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      li {
        padding: 0.5rem 1rem;
        cursor: pointer;
        &:hover {
          background: #fff;
        }
      }
    }
  }
  .commande_page {
    .header {
      padding-top: 2.74rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: start;
        padding: 1.5rem;
        gap: 1rem;
      }
      p {
        color: #000;
        font-family: 'Titillium Web';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5625rem;
        letter-spacing: 0.0125rem;
      }
      .infos {
        display: flex;
        flex-direction: column;
        p.edit {
          color: #0087c7;
          font-family: 'Titillium Web';
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5625rem; /* 166.667% */
          letter-spacing: 0.0125rem;
          text-decoration-line: underline;
          text-transform: uppercase;
          cursor: pointer;
          margin-top: 0.62rem;
          span {
            cursor: pointer;
            svg {
              color: #0087c7;
            }
          }
        }
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h3 {
          color: #000;
          font-family: Oxanium;
          font-size: 1.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.01125rem;
        }
      }
    }

    .commandes_table {
      background-color: #fff;
      margin-top: 1.38rem;
      width: 100%;
      border-collapse: collapse;
      .table_header {
        background: #ececec;
        tr {
          th {
            padding: 1rem;
            color: #000;
            font-family: Oxanium;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.0075rem;
          }
        }
      }
      .table_item {
        tr {
          td:nth-child(1) {
            div {
              margin-left: 0.62rem;
              text-align: left;
              display: flex;
              flex-direction: column;
              p:nth-child(1) {
                color: #000;
                font-family: 'Titillium Web';
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.0125rem;
                text-transform: uppercase;
              }
              p:nth-child(2) {
                color: #5e5e5e;
                font-family: 'Titillium Web';
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.0125rem;
                text-transform: uppercase;
              }
            }
          }
          td:nth-child(2) {
            div {
              margin: 0.5rem 0 0.5rem 3.19rem;
              display: flex;
              gap: 3.31rem;
              .images {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img:nth-child(1) {
                  margin-bottom: 0.62rem;
                  width: 2.75rem;
                  height: 0.6875rem;
                }
                img:nth-child(2) {
                  width: 3.25rem;
                  height: 3rem;
                }
              }
              div {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
                align-items: flex-start;

                text-transform: capitalize;
                p {
                  text-align: start;
                  text-transform: capitalize;
                }
                p:nth-child(1) {
                  color: #000;
                  font-family: Oxanium;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1.5rem; /* 171.429% */
                  letter-spacing: -0.00525rem;
                }
                p:nth-child(2) {
                  color: #000;
                  font-family: 'Titillium Web';
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.0125rem;
                }
                p:nth-child(3) {
                  color: #5e5e5e;
                  font-family: 'Titillium Web';
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.0125rem;
                  span {
                    display: block;
                    color: #eb550e;
                    font-family: 'Titillium Web';
                    text-align: start;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.0125rem;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
          td:nth-child(3) {
            position: relative;
            .quantite {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              justify-content: space-around;
              div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                  color: #000;
                  font-family: 'Titillium Web';
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.0125rem;
                  text-transform: lowercase;
                }
              }
            }
          }
          td:nth-child(4) {
            color: #000;
            font-family: 'Titillium Web';
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
            text-transform: lowercase;
          }
          td:nth-child(5) {
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                color: #fff;
                text-align: center;
                font-family: 'Titillium Web';
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.0125rem;
                text-transform: lowercase;
                border-radius: 0.3125rem;
                background: #07a717;
                padding: 0.2rem 1rem;
                margin-left: 2.31rem;
              }
              span {
                margin-left: 0.66rem;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
